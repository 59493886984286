.abc-checkbox {
  padding-left: 20px; }
.abc-checkbox label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 5px; }
.abc-checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #fff; }
.abc-checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 0;
  margin-left: -21px;
  padding-left: 3px;
  padding-top: 2px;
  font-size: 14px;
  color: #55595c; }
.abc-checkbox input[type="checkbox"],
.abc-checkbox input[type="radio"] {
  opacity: 0;
  z-index: 1; }
.abc-checkbox input[type="checkbox"]:focus + label::before,
.abc-checkbox input[type="radio"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }
.abc-checkbox input[type="checkbox"]:checked + label::after,
.abc-checkbox input[type="radio"]:checked + label::after {
  font-family: "cr-icons";
  content: "\68"; }
.abc-checkbox input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox input[type="radio"]:indeterminate + label::after {
  display: block;
  content: "";
  width: 10px;
  height: 3px;
  background-color: #555555;
  border-radius: 2px;
  margin-left: -16.5px;
  margin-top: 7px; }
.abc-checkbox input[type="checkbox"]:disabled + label,
.abc-checkbox input[type="radio"]:disabled + label {
  opacity: 0.65; }
.abc-checkbox input[type="checkbox"]:disabled + label::before,
.abc-checkbox input[type="radio"]:disabled + label::before {
  background-color: #eceeef;
  cursor: not-allowed; }

.abc-checkbox.abc-checkbox-circle label::before {
  border-radius: 50%; }
.abc-checkbox.checkbox-inline {
  margin-top: 0; }

.abc-checkbox-primary input[type="checkbox"]:checked + label::before,
.abc-checkbox-primary input[type="radio"]:checked + label::before {
  background-color: #43a0cf;
  border-color: #43a0cf; }
.abc-checkbox-primary input[type="checkbox"]:checked + label::after,
.abc-checkbox-primary input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-danger input[type="checkbox"]:checked + label::before,
.abc-checkbox-danger input[type="radio"]:checked + label::before {
  background-color: #d9534f;
  border-color: #d9534f; }
.abc-checkbox-danger input[type="checkbox"]:checked + label::after,
.abc-checkbox-danger input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-info input[type="checkbox"]:checked + label::before,
.abc-checkbox-info input[type="radio"]:checked + label::before {
  background-color: #5bc0de;
  border-color: #5bc0de; }

.abc-checkbox-info input[type="checkbox"]:checked + label::after,
.abc-checkbox-info input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-warning input[type="checkbox"]:checked + label::before,
.abc-checkbox-warning input[type="radio"]:checked + label::before {
  background-color: #f0ad4e;
  border-color: #f0ad4e; }

.abc-checkbox-warning input[type="checkbox"]:checked + label::after,
.abc-checkbox-warning input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-success input[type="checkbox"]:checked + label::before,
.abc-checkbox-success input[type="radio"]:checked + label::before {
  background-color: #5cb85c;
  border-color: #5cb85c; }

.abc-checkbox-success input[type="checkbox"]:checked + label::after,
.abc-checkbox-success input[type="radio"]:checked + label::after {
  color: #fff; }

.abc-checkbox-primary input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-primary input[type="radio"]:indeterminate + label::before {
  background-color: #43a0cf;
  border-color: #43a0cf; }

.abc-checkbox-primary input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-primary input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-danger input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-danger input[type="radio"]:indeterminate + label::before {
  background-color: #d9534f;
  border-color: #d9534f; }

.abc-checkbox-danger input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-danger input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-info input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-info input[type="radio"]:indeterminate + label::before {
  background-color: #5bc0de;
  border-color: #5bc0de; }

.abc-checkbox-info input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-info input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-warning input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-warning input[type="radio"]:indeterminate + label::before {
  background-color: #f0ad4e;
  border-color: #f0ad4e; }

.abc-checkbox-warning input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-warning input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-checkbox-success input[type="checkbox"]:indeterminate + label::before,
.abc-checkbox-success input[type="radio"]:indeterminate + label::before {
  background-color: #5cb85c;
  border-color: #5cb85c; }

.abc-checkbox-success input[type="checkbox"]:indeterminate + label::after,
.abc-checkbox-success input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.abc-radio {
  padding-left: 10px; }
.abc-radio label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 0; }
.abc-radio label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 14px;
  height: 14px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #ccc;
  border-radius: 50%;
  background-color: #fff; }
.abc-radio label::after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 10px;
  height: 10px;
  left: 3px;
  top: 3px;
  margin-left: -20px;
  border-radius: 50%;
  background-color: #55595c;
  transform: scale(0, 0);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33); }
.abc-radio input[type="radio"] {
  opacity: 0;
  z-index: 1; }
.abc-radio input[type="radio"]:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }
.abc-radio input[type="radio"]:checked + label::after {
  transform: scale(1, 1); }
.abc-radio input[type="radio"]:disabled + label {
  opacity: 0.65; }
.abc-radio input[type="radio"]:disabled + label::before {
  cursor: not-allowed; }
.abc-radio.radio-inline {
  margin-top: 0; }

.abc-radio-primary input[type="radio"] + label::after {
  background-color: #43a0cf; }

.abc-radio-primary input[type="radio"]:checked + label::before {
  border-color: #43a0cf; }

.abc-radio-primary input[type="radio"]:checked + label::after {
  background-color: #43a0cf; }

.abc-radio-danger input[type="radio"] + label::after {
  background-color: #d9534f; }

.abc-radio-danger input[type="radio"]:checked + label::before {
  border-color: #d9534f; }

.abc-radio-danger input[type="radio"]:checked + label::after {
  background-color: #d9534f; }

.abc-radio-info input[type="radio"] + label::after {
  background-color: #5bc0de; }

.abc-radio-info input[type="radio"]:checked + label::before {
  border-color: #5bc0de; }

.abc-radio-info input[type="radio"]:checked + label::after {
  background-color: #5bc0de; }

.abc-radio-warning input[type="radio"] + label::after {
  background-color: #f0ad4e; }

.abc-radio-warning input[type="radio"]:checked + label::before {
  border-color: #f0ad4e; }

.abc-radio-warning input[type="radio"]:checked + label::after {
  background-color: #f0ad4e; }

.abc-radio-success input[type="radio"] + label::after {
  background-color: #5cb85c; }

.abc-radio-success input[type="radio"]:checked + label::before {
  border-color: #5cb85c; }

.abc-radio-success input[type="radio"]:checked + label::after {
  background-color: #5cb85c; }

input[type="checkbox"].styled:checked + label:after,
input[type="radio"].styled:checked + label:after {
  font-family: "cr-icons";
  content: "\68"; }

input[type="checkbox"] .styled:checked + label::before,
input[type="radio"] .styled:checked + label::before {
  color: #fff; }

input[type="checkbox"] .styled:checked + label::after,
input[type="radio"] .styled:checked + label::after {
  color: #fff; }

@media (max-width: $screen-phone-max){
  .abc-checkbox {
    padding-left: 40px;
    margin-bottom: 20px;
  }
}
