.rect-auto,
.cr-circle.p51 .cr-slice,
.cr-circle.p52 .cr-slice,
.cr-circle.p53 .cr-slice,
.cr-circle.p54 .cr-slice,
.cr-circle.p55 .cr-slice,
.cr-circle.p56 .cr-slice,
.cr-circle.p57 .cr-slice,
.cr-circle.p58 .cr-slice,
.cr-circle.p59 .cr-slice,
.cr-circle.p60 .cr-slice,
.cr-circle.p61 .cr-slice,
.cr-circle.p62 .cr-slice,
.cr-circle.p63 .cr-slice,
.cr-circle.p64 .cr-slice,
.cr-circle.p65 .cr-slice,
.cr-circle.p66 .cr-slice,
.cr-circle.p67 .cr-slice,
.cr-circle.p68 .cr-slice,
.cr-circle.p69 .cr-slice,
.cr-circle.p70 .cr-slice,
.cr-circle.p71 .cr-slice,
.cr-circle.p72 .cr-slice,
.cr-circle.p73 .cr-slice,
.cr-circle.p74 .cr-slice,
.cr-circle.p75 .cr-slice,
.cr-circle.p76 .cr-slice,
.cr-circle.p77 .cr-slice,
.cr-circle.p78 .cr-slice,
.cr-circle.p79 .cr-slice,
.cr-circle.p80 .cr-slice,
.cr-circle.p81 .cr-slice,
.cr-circle.p82 .cr-slice,
.cr-circle.p83 .cr-slice,
.cr-circle.p84 .cr-slice,
.cr-circle.p85 .cr-slice,
.cr-circle.p86 .cr-slice,
.cr-circle.p87 .cr-slice,
.cr-circle.p88 .cr-slice,
.cr-circle.p89 .cr-slice,
.cr-circle.p90 .cr-slice,
.cr-circle.p91 .cr-slice,
.cr-circle.p92 .cr-slice,
.cr-circle.p93 .cr-slice,
.cr-circle.p94 .cr-slice,
.cr-circle.p95 .cr-slice,
.cr-circle.p96 .cr-slice,
.cr-circle.p97 .cr-slice,
.cr-circle.p98 .cr-slice,
.cr-circle.p99 .cr-slice,
.cr-circle.p100 .cr-slice {
  clip: rect(auto, auto, auto, auto);
}
.pie,
.cr-circle .cr-bar,
.cr-circle.p51 .cr-fill,
.cr-circle.p52 .cr-fill,
.cr-circle.p53 .cr-fill,
.cr-circle.p54 .cr-fill,
.cr-circle.p55 .cr-fill,
.cr-circle.p56 .cr-fill,
.cr-circle.p57 .cr-fill,
.cr-circle.p58 .cr-fill,
.cr-circle.p59 .cr-fill,
.cr-circle.p60 .cr-fill,
.cr-circle.p61 .cr-fill,
.cr-circle.p62 .cr-fill,
.cr-circle.p63 .cr-fill,
.cr-circle.p64 .cr-fill,
.cr-circle.p65 .cr-fill,
.cr-circle.p66 .cr-fill,
.cr-circle.p67 .cr-fill,
.cr-circle.p68 .cr-fill,
.cr-circle.p69 .cr-fill,
.cr-circle.p70 .cr-fill,
.cr-circle.p71 .cr-fill,
.cr-circle.p72 .cr-fill,
.cr-circle.p73 .cr-fill,
.cr-circle.p74 .cr-fill,
.cr-circle.p75 .cr-fill,
.cr-circle.p76 .cr-fill,
.cr-circle.p77 .cr-fill,
.cr-circle.p78 .cr-fill,
.cr-circle.p79 .cr-fill,
.cr-circle.p80 .cr-fill,
.cr-circle.p81 .cr-fill,
.cr-circle.p82 .cr-fill,
.cr-circle.p83 .cr-fill,
.cr-circle.p84 .cr-fill,
.cr-circle.p85 .cr-fill,
.cr-circle.p86 .cr-fill,
.cr-circle.p87 .cr-fill,
.cr-circle.p88 .cr-fill,
.cr-circle.p89 .cr-fill,
.cr-circle.p90 .cr-fill,
.cr-circle.p91 .cr-fill,
.cr-circle.p92 .cr-fill,
.cr-circle.p93 .cr-fill,
.cr-circle.p94 .cr-fill,
.cr-circle.p95 .cr-fill,
.cr-circle.p96 .cr-fill,
.cr-circle.p97 .cr-fill,
.cr-circle.p98 .cr-fill,
.cr-circle.p99 .cr-fill,
.cr-circle.p100 .cr-fill {
  position: absolute;
  border: 0.08em solid #307bbb;
  width: 0.84em;
  height: 0.84em;
  clip: rect(0em, 0.5em, 1em, 0em);
  border-radius: 50%;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}
.pie-cr-fill,
.cr-circle.p51 .cr-bar:after,
.cr-circle.p51 .cr-fill,
.cr-circle.p52 .cr-bar:after,
.cr-circle.p52 .cr-fill,
.cr-circle.p53 .cr-bar:after,
.cr-circle.p53 .cr-fill,
.cr-circle.p54 .cr-bar:after,
.cr-circle.p54 .cr-fill,
.cr-circle.p55 .cr-bar:after,
.cr-circle.p55 .cr-fill,
.cr-circle.p56 .cr-bar:after,
.cr-circle.p56 .cr-fill,
.cr-circle.p57 .cr-bar:after,
.cr-circle.p57 .cr-fill,
.cr-circle.p58 .cr-bar:after,
.cr-circle.p58 .cr-fill,
.cr-circle.p59 .cr-bar:after,
.cr-circle.p59 .cr-fill,
.cr-circle.p60 .cr-bar:after,
.cr-circle.p60 .cr-fill,
.cr-circle.p61 .cr-bar:after,
.cr-circle.p61 .cr-fill,
.cr-circle.p62 .cr-bar:after,
.cr-circle.p62 .cr-fill,
.cr-circle.p63 .cr-bar:after,
.cr-circle.p63 .cr-fill,
.cr-circle.p64 .cr-bar:after,
.cr-circle.p64 .cr-fill,
.cr-circle.p65 .cr-bar:after,
.cr-circle.p65 .cr-fill,
.cr-circle.p66 .cr-bar:after,
.cr-circle.p66 .cr-fill,
.cr-circle.p67 .cr-bar:after,
.cr-circle.p67 .cr-fill,
.cr-circle.p68 .cr-bar:after,
.cr-circle.p68 .cr-fill,
.cr-circle.p69 .cr-bar:after,
.cr-circle.p69 .cr-fill,
.cr-circle.p70 .cr-bar:after,
.cr-circle.p70 .cr-fill,
.cr-circle.p71 .cr-bar:after,
.cr-circle.p71 .cr-fill,
.cr-circle.p72 .cr-bar:after,
.cr-circle.p72 .cr-fill,
.cr-circle.p73 .cr-bar:after,
.cr-circle.p73 .cr-fill,
.cr-circle.p74 .cr-bar:after,
.cr-circle.p74 .cr-fill,
.cr-circle.p75 .cr-bar:after,
.cr-circle.p75 .cr-fill,
.cr-circle.p76 .cr-bar:after,
.cr-circle.p76 .cr-fill,
.cr-circle.p77 .cr-bar:after,
.cr-circle.p77 .cr-fill,
.cr-circle.p78 .cr-bar:after,
.cr-circle.p78 .cr-fill,
.cr-circle.p79 .cr-bar:after,
.cr-circle.p79 .cr-fill,
.cr-circle.p80 .cr-bar:after,
.cr-circle.p80 .cr-fill,
.cr-circle.p81 .cr-bar:after,
.cr-circle.p81 .cr-fill,
.cr-circle.p82 .cr-bar:after,
.cr-circle.p82 .cr-fill,
.cr-circle.p83 .cr-bar:after,
.cr-circle.p83 .cr-fill,
.cr-circle.p84 .cr-bar:after,
.cr-circle.p84 .cr-fill,
.cr-circle.p85 .cr-bar:after,
.cr-circle.p85 .cr-fill,
.cr-circle.p86 .cr-bar:after,
.cr-circle.p86 .cr-fill,
.cr-circle.p87 .cr-bar:after,
.cr-circle.p87 .cr-fill,
.cr-circle.p88 .cr-bar:after,
.cr-circle.p88 .cr-fill,
.cr-circle.p89 .cr-bar:after,
.cr-circle.p89 .cr-fill,
.cr-circle.p90 .cr-bar:after,
.cr-circle.p90 .cr-fill,
.cr-circle.p91 .cr-bar:after,
.cr-circle.p91 .cr-fill,
.cr-circle.p92 .cr-bar:after,
.cr-circle.p92 .cr-fill,
.cr-circle.p93 .cr-bar:after,
.cr-circle.p93 .cr-fill,
.cr-circle.p94 .cr-bar:after,
.cr-circle.p94 .cr-fill,
.cr-circle.p95 .cr-bar:after,
.cr-circle.p95 .cr-fill,
.cr-circle.p96 .cr-bar:after,
.cr-circle.p96 .cr-fill,
.cr-circle.p97 .cr-bar:after,
.cr-circle.p97 .cr-fill,
.cr-circle.p98 .cr-bar:after,
.cr-circle.p98 .cr-fill,
.cr-circle.p99 .cr-bar:after,
.cr-circle.p99 .cr-fill,
.cr-circle.p100 .cr-bar:after,
.cr-circle.p100 .cr-fill {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.cr-circle {
  position: relative;
  font-size: 120px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  float: left;
  margin: 0 0.1em 0.1em 0;
  background-color: $gray-desactive;
}
.cr-circle *,
.cr-circle *:before,
.cr-circle *:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.cr-circle.center {
  float: none;
  margin: 0 auto;
}
.cr-circle.big {
  font-size: 240px;
}
.cr-circle.small {
  font-size: 80px;
}
.cr-circle > span {
  position: absolute;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  width: 5em;
  line-height: 5em;
  font-size: 0.2em;
  color: #cccccc;
  display: block;
  text-align: center;
  white-space: nowrap;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.cr-circle:after {
  position: absolute;
  top: 0.08em;
  left: 0.08em;
  display: block;
  content: " ";
  border-radius: 50%;
  background-color: #f5f5f5;
  width: 0.84em;
  height: 0.84em;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-in;
  -moz-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}
.cr-circle .cr-slice {
  position: absolute;
  width: 1em;
  height: 1em;
  clip: rect(0em, 1em, 1em, 0.5em);
}
.cr-circle.p1 .cr-bar {
  -webkit-transform: rotate(3.6deg);
  -moz-transform: rotate(3.6deg);
  -ms-transform: rotate(3.6deg);
  -o-transform: rotate(3.6deg);
  transform: rotate(3.6deg);
}
.cr-circle.p2 .cr-bar {
  -webkit-transform: rotate(7.2deg);
  -moz-transform: rotate(7.2deg);
  -ms-transform: rotate(7.2deg);
  -o-transform: rotate(7.2deg);
  transform: rotate(7.2deg);
}
.cr-circle.p3 .cr-bar {
  -webkit-transform: rotate(10.8deg);
  -moz-transform: rotate(10.8deg);
  -ms-transform: rotate(10.8deg);
  -o-transform: rotate(10.8deg);
  transform: rotate(10.8deg);
}
.cr-circle.p4 .cr-bar {
  -webkit-transform: rotate(14.4deg);
  -moz-transform: rotate(14.4deg);
  -ms-transform: rotate(14.4deg);
  -o-transform: rotate(14.4deg);
  transform: rotate(14.4deg);
}
.cr-circle.p5 .cr-bar {
  -webkit-transform: rotate(18deg);
  -moz-transform: rotate(18deg);
  -ms-transform: rotate(18deg);
  -o-transform: rotate(18deg);
  transform: rotate(18deg);
}
.cr-circle.p6 .cr-bar {
  -webkit-transform: rotate(21.6deg);
  -moz-transform: rotate(21.6deg);
  -ms-transform: rotate(21.6deg);
  -o-transform: rotate(21.6deg);
  transform: rotate(21.6deg);
}
.cr-circle.p7 .cr-bar {
  -webkit-transform: rotate(25.2deg);
  -moz-transform: rotate(25.2deg);
  -ms-transform: rotate(25.2deg);
  -o-transform: rotate(25.2deg);
  transform: rotate(25.2deg);
}
.cr-circle.p8 .cr-bar {
  -webkit-transform: rotate(28.8deg);
  -moz-transform: rotate(28.8deg);
  -ms-transform: rotate(28.8deg);
  -o-transform: rotate(28.8deg);
  transform: rotate(28.8deg);
}
.cr-circle.p9 .cr-bar {
  -webkit-transform: rotate(32.4deg);
  -moz-transform: rotate(32.4deg);
  -ms-transform: rotate(32.4deg);
  -o-transform: rotate(32.4deg);
  transform: rotate(32.4deg);
}
.cr-circle.p10 .cr-bar {
  -webkit-transform: rotate(36deg);
  -moz-transform: rotate(36deg);
  -ms-transform: rotate(36deg);
  -o-transform: rotate(36deg);
  transform: rotate(36deg);
}
.cr-circle.p11 .cr-bar {
  -webkit-transform: rotate(39.6deg);
  -moz-transform: rotate(39.6deg);
  -ms-transform: rotate(39.6deg);
  -o-transform: rotate(39.6deg);
  transform: rotate(39.6deg);
}
.cr-circle.p12 .cr-bar {
  -webkit-transform: rotate(43.2deg);
  -moz-transform: rotate(43.2deg);
  -ms-transform: rotate(43.2deg);
  -o-transform: rotate(43.2deg);
  transform: rotate(43.2deg);
}
.cr-circle.p13 .cr-bar {
  -webkit-transform: rotate(46.800000000000004deg);
  -moz-transform: rotate(46.800000000000004deg);
  -ms-transform: rotate(46.800000000000004deg);
  -o-transform: rotate(46.800000000000004deg);
  transform: rotate(46.800000000000004deg);
}
.cr-circle.p14 .cr-bar {
  -webkit-transform: rotate(50.4deg);
  -moz-transform: rotate(50.4deg);
  -ms-transform: rotate(50.4deg);
  -o-transform: rotate(50.4deg);
  transform: rotate(50.4deg);
}
.cr-circle.p15 .cr-bar {
  -webkit-transform: rotate(54deg);
  -moz-transform: rotate(54deg);
  -ms-transform: rotate(54deg);
  -o-transform: rotate(54deg);
  transform: rotate(54deg);
}
.cr-circle.p16 .cr-bar {
  -webkit-transform: rotate(57.6deg);
  -moz-transform: rotate(57.6deg);
  -ms-transform: rotate(57.6deg);
  -o-transform: rotate(57.6deg);
  transform: rotate(57.6deg);
}
.cr-circle.p17 .cr-bar {
  -webkit-transform: rotate(61.2deg);
  -moz-transform: rotate(61.2deg);
  -ms-transform: rotate(61.2deg);
  -o-transform: rotate(61.2deg);
  transform: rotate(61.2deg);
}
.cr-circle.p18 .cr-bar {
  -webkit-transform: rotate(64.8deg);
  -moz-transform: rotate(64.8deg);
  -ms-transform: rotate(64.8deg);
  -o-transform: rotate(64.8deg);
  transform: rotate(64.8deg);
}
.cr-circle.p19 .cr-bar {
  -webkit-transform: rotate(68.4deg);
  -moz-transform: rotate(68.4deg);
  -ms-transform: rotate(68.4deg);
  -o-transform: rotate(68.4deg);
  transform: rotate(68.4deg);
}
.cr-circle.p20 .cr-bar {
  -webkit-transform: rotate(72deg);
  -moz-transform: rotate(72deg);
  -ms-transform: rotate(72deg);
  -o-transform: rotate(72deg);
  transform: rotate(72deg);
}
.cr-circle.p21 .cr-bar {
  -webkit-transform: rotate(75.60000000000001deg);
  -moz-transform: rotate(75.60000000000001deg);
  -ms-transform: rotate(75.60000000000001deg);
  -o-transform: rotate(75.60000000000001deg);
  transform: rotate(75.60000000000001deg);
}
.cr-circle.p22 .cr-bar {
  -webkit-transform: rotate(79.2deg);
  -moz-transform: rotate(79.2deg);
  -ms-transform: rotate(79.2deg);
  -o-transform: rotate(79.2deg);
  transform: rotate(79.2deg);
}
.cr-circle.p23 .cr-bar {
  -webkit-transform: rotate(82.8deg);
  -moz-transform: rotate(82.8deg);
  -ms-transform: rotate(82.8deg);
  -o-transform: rotate(82.8deg);
  transform: rotate(82.8deg);
}
.cr-circle.p24 .cr-bar {
  -webkit-transform: rotate(86.4deg);
  -moz-transform: rotate(86.4deg);
  -ms-transform: rotate(86.4deg);
  -o-transform: rotate(86.4deg);
  transform: rotate(86.4deg);
}
.cr-circle.p25 .cr-bar {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.cr-circle.p26 .cr-bar {
  -webkit-transform: rotate(93.60000000000001deg);
  -moz-transform: rotate(93.60000000000001deg);
  -ms-transform: rotate(93.60000000000001deg);
  -o-transform: rotate(93.60000000000001deg);
  transform: rotate(93.60000000000001deg);
}
.cr-circle.p27 .cr-bar {
  -webkit-transform: rotate(97.2deg);
  -moz-transform: rotate(97.2deg);
  -ms-transform: rotate(97.2deg);
  -o-transform: rotate(97.2deg);
  transform: rotate(97.2deg);
}
.cr-circle.p28 .cr-bar {
  -webkit-transform: rotate(100.8deg);
  -moz-transform: rotate(100.8deg);
  -ms-transform: rotate(100.8deg);
  -o-transform: rotate(100.8deg);
  transform: rotate(100.8deg);
}
.cr-circle.p29 .cr-bar {
  -webkit-transform: rotate(104.4deg);
  -moz-transform: rotate(104.4deg);
  -ms-transform: rotate(104.4deg);
  -o-transform: rotate(104.4deg);
  transform: rotate(104.4deg);
}
.cr-circle.p30 .cr-bar {
  -webkit-transform: rotate(108deg);
  -moz-transform: rotate(108deg);
  -ms-transform: rotate(108deg);
  -o-transform: rotate(108deg);
  transform: rotate(108deg);
}
.cr-circle.p31 .cr-bar {
  -webkit-transform: rotate(111.60000000000001deg);
  -moz-transform: rotate(111.60000000000001deg);
  -ms-transform: rotate(111.60000000000001deg);
  -o-transform: rotate(111.60000000000001deg);
  transform: rotate(111.60000000000001deg);
}
.cr-circle.p32 .cr-bar {
  -webkit-transform: rotate(115.2deg);
  -moz-transform: rotate(115.2deg);
  -ms-transform: rotate(115.2deg);
  -o-transform: rotate(115.2deg);
  transform: rotate(115.2deg);
}
.cr-circle.p33 .cr-bar {
  -webkit-transform: rotate(118.8deg);
  -moz-transform: rotate(118.8deg);
  -ms-transform: rotate(118.8deg);
  -o-transform: rotate(118.8deg);
  transform: rotate(118.8deg);
}
.cr-circle.p34 .cr-bar {
  -webkit-transform: rotate(122.4deg);
  -moz-transform: rotate(122.4deg);
  -ms-transform: rotate(122.4deg);
  -o-transform: rotate(122.4deg);
  transform: rotate(122.4deg);
}
.cr-circle.p35 .cr-bar {
  -webkit-transform: rotate(126deg);
  -moz-transform: rotate(126deg);
  -ms-transform: rotate(126deg);
  -o-transform: rotate(126deg);
  transform: rotate(126deg);
}
.cr-circle.p36 .cr-bar {
  -webkit-transform: rotate(129.6deg);
  -moz-transform: rotate(129.6deg);
  -ms-transform: rotate(129.6deg);
  -o-transform: rotate(129.6deg);
  transform: rotate(129.6deg);
}
.cr-circle.p37 .cr-bar {
  -webkit-transform: rotate(133.20000000000002deg);
  -moz-transform: rotate(133.20000000000002deg);
  -ms-transform: rotate(133.20000000000002deg);
  -o-transform: rotate(133.20000000000002deg);
  transform: rotate(133.20000000000002deg);
}
.cr-circle.p38 .cr-bar {
  -webkit-transform: rotate(136.8deg);
  -moz-transform: rotate(136.8deg);
  -ms-transform: rotate(136.8deg);
  -o-transform: rotate(136.8deg);
  transform: rotate(136.8deg);
}
.cr-circle.p39 .cr-bar {
  -webkit-transform: rotate(140.4deg);
  -moz-transform: rotate(140.4deg);
  -ms-transform: rotate(140.4deg);
  -o-transform: rotate(140.4deg);
  transform: rotate(140.4deg);
}
.cr-circle.p40 .cr-bar {
  -webkit-transform: rotate(144deg);
  -moz-transform: rotate(144deg);
  -ms-transform: rotate(144deg);
  -o-transform: rotate(144deg);
  transform: rotate(144deg);
}
.cr-circle.p41 .cr-bar {
  -webkit-transform: rotate(147.6deg);
  -moz-transform: rotate(147.6deg);
  -ms-transform: rotate(147.6deg);
  -o-transform: rotate(147.6deg);
  transform: rotate(147.6deg);
}
.cr-circle.p42 .cr-bar {
  -webkit-transform: rotate(151.20000000000002deg);
  -moz-transform: rotate(151.20000000000002deg);
  -ms-transform: rotate(151.20000000000002deg);
  -o-transform: rotate(151.20000000000002deg);
  transform: rotate(151.20000000000002deg);
}
.cr-circle.p43 .cr-bar {
  -webkit-transform: rotate(154.8deg);
  -moz-transform: rotate(154.8deg);
  -ms-transform: rotate(154.8deg);
  -o-transform: rotate(154.8deg);
  transform: rotate(154.8deg);
}
.cr-circle.p44 .cr-bar {
  -webkit-transform: rotate(158.4deg);
  -moz-transform: rotate(158.4deg);
  -ms-transform: rotate(158.4deg);
  -o-transform: rotate(158.4deg);
  transform: rotate(158.4deg);
}
.cr-circle.p45 .cr-bar {
  -webkit-transform: rotate(162deg);
  -moz-transform: rotate(162deg);
  -ms-transform: rotate(162deg);
  -o-transform: rotate(162deg);
  transform: rotate(162deg);
}
.cr-circle.p46 .cr-bar {
  -webkit-transform: rotate(165.6deg);
  -moz-transform: rotate(165.6deg);
  -ms-transform: rotate(165.6deg);
  -o-transform: rotate(165.6deg);
  transform: rotate(165.6deg);
}
.cr-circle.p47 .cr-bar {
  -webkit-transform: rotate(169.20000000000002deg);
  -moz-transform: rotate(169.20000000000002deg);
  -ms-transform: rotate(169.20000000000002deg);
  -o-transform: rotate(169.20000000000002deg);
  transform: rotate(169.20000000000002deg);
}
.cr-circle.p48 .cr-bar {
  -webkit-transform: rotate(172.8deg);
  -moz-transform: rotate(172.8deg);
  -ms-transform: rotate(172.8deg);
  -o-transform: rotate(172.8deg);
  transform: rotate(172.8deg);
}
.cr-circle.p49 .cr-bar {
  -webkit-transform: rotate(176.4deg);
  -moz-transform: rotate(176.4deg);
  -ms-transform: rotate(176.4deg);
  -o-transform: rotate(176.4deg);
  transform: rotate(176.4deg);
}
.cr-circle.p50 .cr-bar {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.cr-circle.p51 .cr-bar {
  -webkit-transform: rotate(183.6deg);
  -moz-transform: rotate(183.6deg);
  -ms-transform: rotate(183.6deg);
  -o-transform: rotate(183.6deg);
  transform: rotate(183.6deg);
}
.cr-circle.p52 .cr-bar {
  -webkit-transform: rotate(187.20000000000002deg);
  -moz-transform: rotate(187.20000000000002deg);
  -ms-transform: rotate(187.20000000000002deg);
  -o-transform: rotate(187.20000000000002deg);
  transform: rotate(187.20000000000002deg);
}
.cr-circle.p53 .cr-bar {
  -webkit-transform: rotate(190.8deg);
  -moz-transform: rotate(190.8deg);
  -ms-transform: rotate(190.8deg);
  -o-transform: rotate(190.8deg);
  transform: rotate(190.8deg);
}
.cr-circle.p54 .cr-bar {
  -webkit-transform: rotate(194.4deg);
  -moz-transform: rotate(194.4deg);
  -ms-transform: rotate(194.4deg);
  -o-transform: rotate(194.4deg);
  transform: rotate(194.4deg);
}
.cr-circle.p55 .cr-bar {
  -webkit-transform: rotate(198deg);
  -moz-transform: rotate(198deg);
  -ms-transform: rotate(198deg);
  -o-transform: rotate(198deg);
  transform: rotate(198deg);
}
.cr-circle.p56 .cr-bar {
  -webkit-transform: rotate(201.6deg);
  -moz-transform: rotate(201.6deg);
  -ms-transform: rotate(201.6deg);
  -o-transform: rotate(201.6deg);
  transform: rotate(201.6deg);
}
.cr-circle.p57 .cr-bar {
  -webkit-transform: rotate(205.20000000000002deg);
  -moz-transform: rotate(205.20000000000002deg);
  -ms-transform: rotate(205.20000000000002deg);
  -o-transform: rotate(205.20000000000002deg);
  transform: rotate(205.20000000000002deg);
}
.cr-circle.p58 .cr-bar {
  -webkit-transform: rotate(208.8deg);
  -moz-transform: rotate(208.8deg);
  -ms-transform: rotate(208.8deg);
  -o-transform: rotate(208.8deg);
  transform: rotate(208.8deg);
}
.cr-circle.p59 .cr-bar {
  -webkit-transform: rotate(212.4deg);
  -moz-transform: rotate(212.4deg);
  -ms-transform: rotate(212.4deg);
  -o-transform: rotate(212.4deg);
  transform: rotate(212.4deg);
}
.cr-circle.p60 .cr-bar {
  -webkit-transform: rotate(216deg);
  -moz-transform: rotate(216deg);
  -ms-transform: rotate(216deg);
  -o-transform: rotate(216deg);
  transform: rotate(216deg);
}
.cr-circle.p61 .cr-bar {
  -webkit-transform: rotate(219.6deg);
  -moz-transform: rotate(219.6deg);
  -ms-transform: rotate(219.6deg);
  -o-transform: rotate(219.6deg);
  transform: rotate(219.6deg);
}
.cr-circle.p62 .cr-bar {
  -webkit-transform: rotate(223.20000000000002deg);
  -moz-transform: rotate(223.20000000000002deg);
  -ms-transform: rotate(223.20000000000002deg);
  -o-transform: rotate(223.20000000000002deg);
  transform: rotate(223.20000000000002deg);
}
.cr-circle.p63 .cr-bar {
  -webkit-transform: rotate(226.8deg);
  -moz-transform: rotate(226.8deg);
  -ms-transform: rotate(226.8deg);
  -o-transform: rotate(226.8deg);
  transform: rotate(226.8deg);
}
.cr-circle.p64 .cr-bar {
  -webkit-transform: rotate(230.4deg);
  -moz-transform: rotate(230.4deg);
  -ms-transform: rotate(230.4deg);
  -o-transform: rotate(230.4deg);
  transform: rotate(230.4deg);
}
.cr-circle.p65 .cr-bar {
  -webkit-transform: rotate(234deg);
  -moz-transform: rotate(234deg);
  -ms-transform: rotate(234deg);
  -o-transform: rotate(234deg);
  transform: rotate(234deg);
}
.cr-circle.p66 .cr-bar {
  -webkit-transform: rotate(237.6deg);
  -moz-transform: rotate(237.6deg);
  -ms-transform: rotate(237.6deg);
  -o-transform: rotate(237.6deg);
  transform: rotate(237.6deg);
}
.cr-circle.p67 .cr-bar {
  -webkit-transform: rotate(241.20000000000002deg);
  -moz-transform: rotate(241.20000000000002deg);
  -ms-transform: rotate(241.20000000000002deg);
  -o-transform: rotate(241.20000000000002deg);
  transform: rotate(241.20000000000002deg);
}
.cr-circle.p68 .cr-bar {
  -webkit-transform: rotate(244.8deg);
  -moz-transform: rotate(244.8deg);
  -ms-transform: rotate(244.8deg);
  -o-transform: rotate(244.8deg);
  transform: rotate(244.8deg);
}
.cr-circle.p69 .cr-bar {
  -webkit-transform: rotate(248.4deg);
  -moz-transform: rotate(248.4deg);
  -ms-transform: rotate(248.4deg);
  -o-transform: rotate(248.4deg);
  transform: rotate(248.4deg);
}
.cr-circle.p70 .cr-bar {
  -webkit-transform: rotate(252deg);
  -moz-transform: rotate(252deg);
  -ms-transform: rotate(252deg);
  -o-transform: rotate(252deg);
  transform: rotate(252deg);
}
.cr-circle.p71 .cr-bar {
  -webkit-transform: rotate(255.6deg);
  -moz-transform: rotate(255.6deg);
  -ms-transform: rotate(255.6deg);
  -o-transform: rotate(255.6deg);
  transform: rotate(255.6deg);
}
.cr-circle.p72 .cr-bar {
  -webkit-transform: rotate(259.2deg);
  -moz-transform: rotate(259.2deg);
  -ms-transform: rotate(259.2deg);
  -o-transform: rotate(259.2deg);
  transform: rotate(259.2deg);
}
.cr-circle.p73 .cr-bar {
  -webkit-transform: rotate(262.8deg);
  -moz-transform: rotate(262.8deg);
  -ms-transform: rotate(262.8deg);
  -o-transform: rotate(262.8deg);
  transform: rotate(262.8deg);
}
.cr-circle.p74 .cr-bar {
  -webkit-transform: rotate(266.40000000000003deg);
  -moz-transform: rotate(266.40000000000003deg);
  -ms-transform: rotate(266.40000000000003deg);
  -o-transform: rotate(266.40000000000003deg);
  transform: rotate(266.40000000000003deg);
}
.cr-circle.p75 .cr-bar {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}
.cr-circle.p76 .cr-bar {
  -webkit-transform: rotate(273.6deg);
  -moz-transform: rotate(273.6deg);
  -ms-transform: rotate(273.6deg);
  -o-transform: rotate(273.6deg);
  transform: rotate(273.6deg);
}
.cr-circle.p77 .cr-bar {
  -webkit-transform: rotate(277.2deg);
  -moz-transform: rotate(277.2deg);
  -ms-transform: rotate(277.2deg);
  -o-transform: rotate(277.2deg);
  transform: rotate(277.2deg);
}
.cr-circle.p78 .cr-bar {
  -webkit-transform: rotate(280.8deg);
  -moz-transform: rotate(280.8deg);
  -ms-transform: rotate(280.8deg);
  -o-transform: rotate(280.8deg);
  transform: rotate(280.8deg);
}
.cr-circle.p79 .cr-bar {
  -webkit-transform: rotate(284.40000000000003deg);
  -moz-transform: rotate(284.40000000000003deg);
  -ms-transform: rotate(284.40000000000003deg);
  -o-transform: rotate(284.40000000000003deg);
  transform: rotate(284.40000000000003deg);
}
.cr-circle.p80 .cr-bar {
  -webkit-transform: rotate(288deg);
  -moz-transform: rotate(288deg);
  -ms-transform: rotate(288deg);
  -o-transform: rotate(288deg);
  transform: rotate(288deg);
}
.cr-circle.p81 .cr-bar {
  -webkit-transform: rotate(291.6deg);
  -moz-transform: rotate(291.6deg);
  -ms-transform: rotate(291.6deg);
  -o-transform: rotate(291.6deg);
  transform: rotate(291.6deg);
}
.cr-circle.p82 .cr-bar {
  -webkit-transform: rotate(295.2deg);
  -moz-transform: rotate(295.2deg);
  -ms-transform: rotate(295.2deg);
  -o-transform: rotate(295.2deg);
  transform: rotate(295.2deg);
}
.cr-circle.p83 .cr-bar {
  -webkit-transform: rotate(298.8deg);
  -moz-transform: rotate(298.8deg);
  -ms-transform: rotate(298.8deg);
  -o-transform: rotate(298.8deg);
  transform: rotate(298.8deg);
}
.cr-circle.p84 .cr-bar {
  -webkit-transform: rotate(302.40000000000003deg);
  -moz-transform: rotate(302.40000000000003deg);
  -ms-transform: rotate(302.40000000000003deg);
  -o-transform: rotate(302.40000000000003deg);
  transform: rotate(302.40000000000003deg);
}
.cr-circle.p85 .cr-bar {
  -webkit-transform: rotate(306deg);
  -moz-transform: rotate(306deg);
  -ms-transform: rotate(306deg);
  -o-transform: rotate(306deg);
  transform: rotate(306deg);
}
.cr-circle.p86 .cr-bar {
  -webkit-transform: rotate(309.6deg);
  -moz-transform: rotate(309.6deg);
  -ms-transform: rotate(309.6deg);
  -o-transform: rotate(309.6deg);
  transform: rotate(309.6deg);
}
.cr-circle.p87 .cr-bar {
  -webkit-transform: rotate(313.2deg);
  -moz-transform: rotate(313.2deg);
  -ms-transform: rotate(313.2deg);
  -o-transform: rotate(313.2deg);
  transform: rotate(313.2deg);
}
.cr-circle.p88 .cr-bar {
  -webkit-transform: rotate(316.8deg);
  -moz-transform: rotate(316.8deg);
  -ms-transform: rotate(316.8deg);
  -o-transform: rotate(316.8deg);
  transform: rotate(316.8deg);
}
.cr-circle.p89 .cr-bar {
  -webkit-transform: rotate(320.40000000000003deg);
  -moz-transform: rotate(320.40000000000003deg);
  -ms-transform: rotate(320.40000000000003deg);
  -o-transform: rotate(320.40000000000003deg);
  transform: rotate(320.40000000000003deg);
}
.cr-circle.p90 .cr-bar {
  -webkit-transform: rotate(324deg);
  -moz-transform: rotate(324deg);
  -ms-transform: rotate(324deg);
  -o-transform: rotate(324deg);
  transform: rotate(324deg);
}
.cr-circle.p91 .cr-bar {
  -webkit-transform: rotate(327.6deg);
  -moz-transform: rotate(327.6deg);
  -ms-transform: rotate(327.6deg);
  -o-transform: rotate(327.6deg);
  transform: rotate(327.6deg);
}
.cr-circle.p92 .cr-bar {
  -webkit-transform: rotate(331.2deg);
  -moz-transform: rotate(331.2deg);
  -ms-transform: rotate(331.2deg);
  -o-transform: rotate(331.2deg);
  transform: rotate(331.2deg);
}
.cr-circle.p93 .cr-bar {
  -webkit-transform: rotate(334.8deg);
  -moz-transform: rotate(334.8deg);
  -ms-transform: rotate(334.8deg);
  -o-transform: rotate(334.8deg);
  transform: rotate(334.8deg);
}
.cr-circle.p94 .cr-bar {
  -webkit-transform: rotate(338.40000000000003deg);
  -moz-transform: rotate(338.40000000000003deg);
  -ms-transform: rotate(338.40000000000003deg);
  -o-transform: rotate(338.40000000000003deg);
  transform: rotate(338.40000000000003deg);
}
.cr-circle.p95 .cr-bar {
  -webkit-transform: rotate(342deg);
  -moz-transform: rotate(342deg);
  -ms-transform: rotate(342deg);
  -o-transform: rotate(342deg);
  transform: rotate(342deg);
}
.cr-circle.p96 .cr-bar {
  -webkit-transform: rotate(345.6deg);
  -moz-transform: rotate(345.6deg);
  -ms-transform: rotate(345.6deg);
  -o-transform: rotate(345.6deg);
  transform: rotate(345.6deg);
}
.cr-circle.p97 .cr-bar {
  -webkit-transform: rotate(349.2deg);
  -moz-transform: rotate(349.2deg);
  -ms-transform: rotate(349.2deg);
  -o-transform: rotate(349.2deg);
  transform: rotate(349.2deg);
}
.cr-circle.p98 .cr-bar {
  -webkit-transform: rotate(352.8deg);
  -moz-transform: rotate(352.8deg);
  -ms-transform: rotate(352.8deg);
  -o-transform: rotate(352.8deg);
  transform: rotate(352.8deg);
}
.cr-circle.p99 .cr-bar {
  -webkit-transform: rotate(356.40000000000003deg);
  -moz-transform: rotate(356.40000000000003deg);
  -ms-transform: rotate(356.40000000000003deg);
  -o-transform: rotate(356.40000000000003deg);
  transform: rotate(356.40000000000003deg);
}
.cr-circle.p100 .cr-bar {
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
}
.cr-circle:hover {
  cursor: default;
}
.cr-circle:hover > span {
  width: 3.33em;
  line-height: 3.33em;
  font-size: 0.3em;
  color: #307bbb;
}
.cr-circle:hover:after {
  top: 0.04em;
  left: 0.04em;
  width: 0.92em;
  height: 0.92em;
}
.cr-circle.dark {
  background-color: #777777;
}
.cr-circle.dark .cr-bar,
.cr-circle.dark .cr-fill {
  border-color: #c6ff00 !important;
}
.cr-circle.dark > span {
  color: #777777;
}
.cr-circle.dark:after {
  background-color: #666666;
}
.cr-circle.dark:hover > span {
  color: #c6ff00;
}
.cr-circle.green .cr-bar,
.cr-circle.green .cr-fill {
  border-color: $green !important;
}
.cr-circle.green:hover > span {
  color: #4db53c;
}


