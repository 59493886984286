

section button {
  margin: 0 auto!important;
  font-size: $font-small;
  padding: 1.25rem 2.5rem;
  display: block;
  background-color: $orange;
  border: 1px solid transparent;
  color: $white;
  font-weight: $font-weight-base;
  font-family: $font-family-light;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &.cr-button-send-request{
    .cr-container-button-send-requestet{
      position: relative;
      top: -2px;
      i{
        position: relative;
        font-size: 34px;
        top: 3px;
      }
      span{
        position: relative;
        top: -3px;
      }
    }

  }
  &.cr-btn-next{
    position: relative;
    right: 30px;
  }
  &.cr-btn-prev{
    position: relative;
    left: 30px;
  }
  &.cr-save-button-container{
    border-radius: $remove-border;
  }
  &.cr-cancel-button-container{
    background-color: $gray-desactive;
    border-radius: $remove-border;
    &:hover{
      background-color: $white;
      color: $gray-desactive;
      border-color: $gray-desactive;
    }
  }
  &.cr-download-documents{
    i{
      color: $white;
      font-size: $font-medium;
      position: relative;
      top:5px;
    }
    &:hover{
      i{
        color: $orange;
      }
    }
  }

}

section button:hover {
  background-color: $white;
  color: $orange;
  border-color: $orange;
}

section{
  &.clearfix{
    margin-bottom: 15px;
  }
}