ul {
  margin: $remove-margin;
  width: 100%;
  display: inline-flex;
  &.cr-menu-application-container{
    margin-left: $remove-margin;
    margin-right: -$padding-medium;
    padding-right: $remove-padding;
    li {
      &.nav-item {
        width: 20%;
        padding-bottom: $padding-medium;
        padding-top: $padding-medium;
      }
      &:hover {
        border-color: transparent;
      }
      a {
        color: $primary-brand;
        padding: 0 10px;
        text-decoration: none;
        &:hover {
          border-color: transparent !important;
        }
      }
      .cr-icon-container {
        margin-right: $margin-base;
        margin-top: ($margin-small - 2);
        i {
          font-size: $font-xlarge;
        }
      }
      .cr-steps-description {
        .cr-steps-title {
          font-weight: $font-weight-bold;
        }
        .cr-steps-description {
          font-size: ($font-xsmall + 2);
        }
      }
    }
  }
}




