
.cssload-clock{
  border-radius: 180px;
  border: 9px solid rgb(67,160,207);
  height: 240px;
  width: 240px;
  position: relative;
  left: 35%;
  left: calc(50% - 129px);
  left: -o-calc(50% - 129px);
  left: -ms-calc(50% - 129px);
  left: -webkit-calc(50% - 129px);
  left: -moz-calc(50% - 129px);
  background-color: #e0e0e0;

  margin-bottom: 40px;
  margin-top: 40px;
}
.cssload-clock:after{
  content: "";
  position: absolute;
  background-color: rgb(67,160,207);
  top:6px;
  left: 48%;
  height: 114px;
  width: 12px;
  border-radius: 15px;
  transform-origin: 50% 97%;
  -o-transform-origin: 50% 97%;
  -ms-transform-origin: 50% 97%;
  -webkit-transform-origin: 50% 97%;
  -moz-transform-origin: 50% 97%;
  animation: grdAiguille 2.3s linear infinite;
  -o-animation: grdAiguille 2.3s linear infinite;
  -ms-animation: grdAiguille 2.3s linear infinite;
  -webkit-animation: grdAiguille 2.3s linear infinite;
  -moz-animation: grdAiguille 2.3s linear infinite;
}

.cssload-clock > img {
  height: auto;
  left: 10px;
  position: relative;
  top: 66px;
  width: 92%;
  z-index: 1;
}

.cssload-clock:before{
  content: "";
  position: absolute;
  background-color: rgb(67,160,207);
  top:18px;
  left: 48%;
  height: 105px;
  width: 12px;
  border-radius: 15px;
  transform-origin: 50% 94%;
  -o-transform-origin: 50% 94%;
  -ms-transform-origin: 50% 94%;
  -webkit-transform-origin: 50% 94%;
  -moz-transform-origin: 50% 94%;
  animation: ptAiguille 13.8s linear infinite;
  -o-animation: ptAiguille 13.8s linear infinite;
  -ms-animation: ptAiguille 13.8s linear infinite;
  -webkit-animation: ptAiguille 13.8s linear infinite;
  -moz-animation: ptAiguille 13.8s linear infinite;
}



@keyframes grdAiguille{
  0%{transform:rotate(0deg);}
  100%{transform:rotate(360deg);}
}

@-o-keyframes grdAiguille{
  0%{-o-transform:rotate(0deg);}
  100%{-o-transform:rotate(360deg);}
}

@-ms-keyframes grdAiguille{
  0%{-ms-transform:rotate(0deg);}
  100%{-ms-transform:rotate(360deg);}
}

@-webkit-keyframes grdAiguille{
  0%{-webkit-transform:rotate(0deg);}
  100%{-webkit-transform:rotate(360deg);}
}

@-moz-keyframes grdAiguille{
  0%{-moz-transform:rotate(0deg);}
  100%{-moz-transform:rotate(360deg);}
}

@keyframes ptAiguille{
  0%{transform:rotate(0deg);}
  100%{transform:rotate(360deg);}
}

@-o-keyframes ptAiguille{
  0%{-o-transform:rotate(0deg);}
  100%{-o-transform:rotate(360deg);}
}

@-ms-keyframes ptAiguille{
  0%{-ms-transform:rotate(0deg);}
  100%{-ms-transform:rotate(360deg);}
}

@-webkit-keyframes ptAiguille{
  0%{-webkit-transform:rotate(0deg);}
  100%{-webkit-transform:rotate(360deg);}
}

@-moz-keyframes ptAiguille{
  0%{-moz-transform:rotate(0deg);}
  100%{-moz-transform:rotate(360deg);}
}





.cssload-clock.cr-loader-photo::before {
  animation: 13.8s linear 0s normal none infinite running ptAiguille;
  background-color: #43a0cf;
  border-radius: 15px;
  content: "";
  height: 42px;
  left: 48%;
  position: absolute;
  top: 18px;
  transform-origin: 50% 94% 0;
  width: 12px;
}
.cssload-clock.cr-loader-photo::after {
  animation: 2.3s linear 0s normal none infinite running grdAiguille;
  background-color: #43a0cf;
  border-radius: 15px;
  content: "";
  height: 51px;
  left: 48%;
  position: absolute;
  top: 6px;
  transform-origin: 50% 97% 0;
  width: 12px;
}
.cssload-clock.cr-loader-photo {
  background-color: #e0e0e0;
  border: 9px solid #43a0cf;
  border-radius: 180px;
  height: 100px;
  left: calc(50% - 129px);
  margin-bottom: 40px;
  margin-top: 40px;
  position: relative;
  width: 100px;
}
.cssload-clock.cr-loader-photo > img {
  left: 13px;
  position: relative;
  top: 39px;
}