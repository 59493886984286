
// Register Form Home
.floating-placeholder{
  i{
    color: $gray-desactive;
    font-size: $font-medium;
  }
  &.cr-valid{
    i{
      color: $green;
      font-size: $font-medium;
    }
  }
}

// Menú application
.cr-menu-application-container{
    li {
      &.cr-validation-ok {
        background-color: $validation-ok-color;
        .cr-icon-container {
          i {
            color: $validation-text-ok-color;
          }
        }
        .cr-steps-description {
          span {
            color: $validation-text-ok-color;
          }
        }
      }
      &.cr-active {
        background-color: $active-color;
        .cr-icon-container {
          i {
            color: $white;
          }
        }
        .cr-steps-description {
          span {
            color: $white;
          }
        }
      }
      &.cr-error {
        background-color: $error-color;
        .cr-icon-container {
          i {
            color: $error-text-color;
          }
        }
        .cr-steps-description {
          span {
            color: $error-text-color;
          }
        }
      }
      &.cr-disabled {
        background-color: $disabled-color;
        .cr-icon-container {
          i {
            color: $disabled-text-color;
          }
        }
        .cr-steps-description {
          span {
            color: $disabled-text-color;
          }
        }
        a {
          cursor: no-drop;
        }
      }
    }
  }

// inputs steps application
.cr-request-forms-container{
  .cr-row-form-container{
    .cr-form-element {
      .icon-cr-valid-ok, .icon-cr-valid-error {
        display: none;
      }
      &.cr-error {
        border: 1px solid $error-color!important;
        .cr-icon-input-icon-container {
          background-color: $error-color!important;
        }
        .cr-input-form-container, .cr-elements-form-container {
          i {
            color: $error-color;
            font-size: $icon-size-validation;
            position: absolute;
            right: 5px;
            top: 6px;
            &.icon-cr-valid-ok {
              display: none;
            }
            &.icon-cr-valid-error {
              display: block !important;
            }
          }
        }
      }
      &.cr-valid {
        border: 1px solid $valid-ok-color!important;
        .cr-icon-input-icon-container {
          background-color: $valid-ok-color!important;
        }
        .cr-input-form-container, .cr-elements-form-container {
          i {
            color: $valid-ok-color;
            font-size: $icon-size-validation;
            position: absolute;
            right: 5px;
            top: 6px;
            &.icon-cr-valid-error {
              display: none;
            }
            &.icon-cr-valid-ok {
              display: block !important;
            }
          }
        }
        select.cr-minimal{
          border: thin solid #43a0cf!important;
          //border: 1px solid #43a0cf;
        }
      }
    }
  }
}


@media (max-width: $screen-laptop-max) {
  // Menú application
  .cr-steps-containers {
    .card {
      ul {
        &.nav-tabs {
          li {
            &.cr-active, &.cr-disabled, &.cr-error, &.cr-validation-ok{
              background-color: transparent;
            }
            &.nav-item {
              a {
                &.nav-link {
                  .cr-icon-container-mobile {
                    border: 2px solid #026a8d;
                    border-radius: 50%;
                    height: 40px;
                    margin: 0 auto;
                    padding: 0x;
                    width: 40px;
                    background: rgba(14, 150, 191, 1);
                    background: -moz-linear-gradient(top, rgba(14, 150, 191, 1) 0%, rgba(2, 106, 141, 1) 100%);
                    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(14, 150, 191, 1)), color-stop(100%, rgba(2, 106, 141, 1)));
                    background: -webkit-linear-gradient(top, rgba(14, 150, 191, 1) 0%, rgba(2, 106, 141, 1) 100%);
                    background: -o-linear-gradient(top, rgba(14, 150, 191, 1) 0%, rgba(2, 106, 141, 1) 100%);
                    background: -ms-linear-gradient(top, rgba(14, 150, 191, 1) 0%, rgba(2, 106, 141, 1) 100%);
                    background: linear-gradient(to bottom, rgba(14, 150, 191, 1) 0%, rgba(2, 106, 141, 1) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0e96bf', endColorstr='#026a8d', GradientType=0);
                    span {
                      &.cr-number-steps {
                        color: $white;
                        text-shadow: 1px 1px 0 rgba(200, 200, 200, 0.6), -1px -1px 1px rgba(100, 100, 100, 0.67);
                        font-size: 28px;
                        font-weight: 500;
                        position: relative;
                        top: 9px;

                      }
                    }
                    i{
                      display: none;
                    }
                  }
                  .cr-icon-container-mobile-visitable {
                    border: 2px solid #dcdcdc;
                    border-radius: 50%;
                    height: 40px;
                    margin: 0 auto;
                    padding: 0;
                    width: 40px;

                    background: rgba(224, 225, 225, 1);
                    background: -moz-linear-gradient(top, rgba(224, 225, 225, 1) 0%, rgba(172, 172, 172, 1) 100%);
                    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(224, 225, 225, 1)), color-stop(100%, rgba(172, 172, 172, 1)));
                    background: -webkit-linear-gradient(top, rgba(224, 225, 225, 1) 0%, rgba(172, 172, 172, 1) 100%);
                    background: -o-linear-gradient(top, rgba(224, 225, 225, 1) 0%, rgba(172, 172, 172, 1) 100%);
                    background: -ms-linear-gradient(top, rgba(224, 225, 225, 1) 0%, rgba(172, 172, 172, 1) 100%);
                    background: linear-gradient(to bottom, rgba(224, 225, 225, 1) 0%, rgba(172, 172, 172, 1) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e0e1e1', endColorstr='#acacac', GradientType=0);

                    span {
                      &.cr-number-steps {
                        color: $white;
                        text-shadow: 1px 1px 0 rgba(200, 200, 200, 0.6), -1px -1px 1px rgba(100, 100, 100, 0.67);
                        font-size: 28px;
                        font-weight: 500;
                        position: relative;
                        top: 9px;

                      }
                    }
                    i{
                      display: none;
                    }
                  }
                  .cr-icon-container-mobile-ok {
                    // background-color: #90c642;
                    border: 2px solid #6ead52;
                    border-radius: 50%;
                    height: 40px;
                    margin: 0 auto;
                    padding: 0;
                    width: 40px;

                    background: rgba(143, 198, 66, 1);
                    background: -moz-linear-gradient(top, rgba(143, 198, 66, 1) 0%, rgba(109, 173, 82, 1) 100%);
                    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(143, 198, 66, 1)), color-stop(100%, rgba(109, 173, 82, 1)));
                    background: -webkit-linear-gradient(top, rgba(143, 198, 66, 1) 0%, rgba(109, 173, 82, 1) 100%);
                    background: -o-linear-gradient(top, rgba(143, 198, 66, 1) 0%, rgba(109, 173, 82, 1) 100%);
                    background: -ms-linear-gradient(top, rgba(143, 198, 66, 1) 0%, rgba(109, 173, 82, 1) 100%);
                    background: linear-gradient(to bottom, rgba(143, 198, 66, 1) 0%, rgba(109, 173, 82, 1) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8fc642', endColorstr='#6dad52', GradientType=0);
                    span {
                      display: none;
                    }
                    i {
                      font-size: 28px;
                      text-shadow: 1px 1px 0 rgba(200, 200, 200, 0.6), -1px -1px 1px rgba(100, 100, 100, 0.67);
                      position: relative;
                      top: 6px;
                      color: $white;
                      &.icon-cr-error, &.icon-cr-valid-error{
                        display: none;
                      }
                    }
                  }
                  .cr-icon-container-mobile-error{
                    border: 2px solid #ce2020;
                    border-radius: 50%;
                    height: 40px;
                    margin: 0 auto;
                    padding: 0;
                    width: 40px;

                    background: rgba(234, 106, 106, 1);
                    background: -moz-linear-gradient(top, rgba(234, 106, 106, 1) 0%, rgba(206, 32, 32, 1) 100%);
                    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(234, 106, 106, 1)), color-stop(100%, rgba(206, 32, 32, 1)));
                    background: -webkit-linear-gradient(top, rgba(234, 106, 106, 1) 0%, rgba(206, 32, 32, 1) 100%);
                    background: -o-linear-gradient(top, rgba(234, 106, 106, 1) 0%, rgba(206, 32, 32, 1) 100%);
                    background: -ms-linear-gradient(top, rgba(234, 106, 106, 1) 0%, rgba(206, 32, 32, 1) 100%);
                    background: linear-gradient(to bottom, rgba(234, 106, 106, 1) 0%, rgba(206, 32, 32, 1) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8fc642', endColorstr='#6dad52', GradientType=0);
                    span{
                      display: none;
                    }
                    i {
                      font-size: 28px;
                      position: relative;
                      top: 5px;
                      color: $white;
                      &.icon-cr-check-ok, &.icon-cr-valid-ok{
                        display: none;

                      }
                    }

                  }
                  .cr-icon-container-mobile-off {
                    border: 2px solid #acacac;
                    border-radius: 50%;
                    height: 40px;
                    margin: 0 auto;
                    padding: 0;
                    width: 40px;

                    background: rgba(224, 225, 225, 1);
                    background: -moz-linear-gradient(top, rgba(224, 225, 225, 1) 0%, rgba(172, 172, 172, 1) 100%);
                    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(224, 225, 225, 1)), color-stop(100%, rgba(172, 172, 172, 1)));
                    background: -webkit-linear-gradient(top, rgba(224, 225, 225, 1) 0%, rgba(172, 172, 172, 1) 100%);
                    background: -o-linear-gradient(top, rgba(224, 225, 225, 1) 0%, rgba(172, 172, 172, 1) 100%);
                    background: -ms-linear-gradient(top, rgba(224, 225, 225, 1) 0%, rgba(172, 172, 172, 1) 100%);
                    background: linear-gradient(to bottom, rgba(224, 225, 225, 1) 0%, rgba(172, 172, 172, 1) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e0e1e1', endColorstr='#acacac', GradientType=0);

                    span {
                      &.cr-number-steps {
                        color: $white;
                        text-shadow: 1px 1px 0 rgba(200, 200, 200, 0.6), -1px -1px 1px rgba(100, 100, 100, 0.67);
                        font-size: 28px;
                        font-weight: 500;
                        position: relative;
                        top: 9px;

                      }
                    }
                    i{
                      display: none;
                    }

                  }
                  .cr-title-step {
                    font-size: 10px;
                    font-weight: bold;
                    color: #329abd;
                    position: relative;
                    text-align: left;
                  }
                  .cr-title-step-ok {
                    font-size: 10px;
                    font-weight: bold;
                    color: #6ead52;
                    position: relative;
                    text-align: left;
                  }
                  .cr-title-step-error{
                    font-size: 10px;
                    font-weight: bold;
                    color: #ce2020;
                    position: relative;
                    text-align: left;
                  }
                  .cr-title-step-off {
                    font-size: 10px;
                    font-weight: 400;
                    color: #acacac;
                    position: relative;
                    text-align: left;
                  }
                  .cr-title-step-visitable {
                    font-size: 10px;
                    font-weight: 400;
                    color: #acacac;
                    position: relative;
                    text-align: left;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // inputs steps application
  .cr-request-forms-container{
    overflow: hidden;
    .cr-form-steps-container {
      .cr-row-form-container {
        .cr-form-element {
          &.cr-valid, &.cr-error {
            .cr-elements-form-container {
              i {
                top: 5px !important;;
                &.cr-down-check {
                  top: 50px;
                }
              }
            }
          }
          &.cr-valid {
            border: 1px solid $valid-ok-color !important;
            margin: 0 0 5px;
            .cr-label-mobile{
              background-color: rgba(67, 160, 207, 0.2)!important;
              span{
                color: $valid-ok-color;
              }
            }
            .dropdown{
              button{
                border: 1px solid $valid-ok-color !important;
              }
            }
          }
          &.cr-error {
            border: 1px solid $error-color !important;
            margin: 0 0 3px;
            .cr-label-mobile{
              background-color: rgba(242, 114, 114, 0.2)!important;
              span{
                color: $error-color;
              }
            }
          }
        }
      }
    }
  }

  .floating-placeholder{
    i{
      color: $gray-desactive;
      font-size: $font-medium;
    }
    &.cr-valid{
      i{
        color: $green!important;
        font-size: $font-medium;
      }
    }
  }
}


