@import "cr-variables-colors";

//// Fonts

$font-family-base:  'LatoWeb';
$font-family-light: 'LatoWeblight';
$font-base:          20px !default;
$font-size-icons:    20px !default;
$font-uppercase:     uppercase;
$font-xsmall:        ceil(($font-base * 0.65)) !default; // ~10px
$font-small:         ceil(($font-base * 0.75 - 1)) !default; // ~14px
$font-small-large:   ceil(($font-base * 0.9)) !default; // ~18px
$font-medium:        ceil(($font-base * 1.25)) !default; // ~25px
$font-large:         ceil(($font-base * 1.50)) !default; // ~30px
$font-xlarge:        ceil(($font-base * 1.75)) !default; // ~35px


$font-weight-base:        300 !default;
$font-weight-bold:        bold !default;


// Paddings

$remove-padding:            0;
$padding-base:              10px !default;
$padding-small:             ($padding-base - 5); // 5px
$padding-medium:            ($padding-base + 5); // 15px
$padding-large:             ($padding-base * 2); // 20px
$padding-xlarge:            ($padding-base * 2 + 5); // 25px
$padding-icon-forms:        4px 6px 3px;

// Margins

$remove-margin:             0;
$margin-base:               10px !default;
$margin-small:              ($margin-base - 5); // 5px
$margin-medium:             ($margin-base + 5); // 15px
$margin-large:              ($margin-base * 2); // 20px
$margin-xlarge:             ($margin-base * 2 + 5); // 25px
$margin-bigger:             ($margin-base * 4 ); // 40px
$margin-auto:               0 auto;

// Borders

$remove-border:             0;
$border-form-initial:       1px solid rgba($gray-dark, 100%);

$border-radius-base:        5px !default;
$border-radius-small:       ($border-radius-base * 2); // 10px

$border-base:               1px solid $gray-desactive;
$border-base-dotted:        1px dotted $blue;


// Mix

$remove-value:              0;

$cursor-on:                 pointer !default;
$full-height:               100% !default;
$full-width:                100% !default;
$half-width:                50% !default;

$separator-height:          1px !default;








// Extra small screen / phone
$screen-phone-min:          400px !default;
$screen-phone-max:          576px !default;
// Small screen / tablet
$screen-tablet-min:         577px !default;
$screen-tablet-max:         768px !default;
// Medium screen / desktop
$screen-laptop-min:         769px !default;
$screen-laptop-max:         992px !default;
// Large screen / wide desktop large
$screen-desktop-lg-min:     993px !default;
$screen-desktop-lg-max:     1200px !default;
//full screem
$screen-desktop-xl-min:     1201px !default;


//sass-to-js package. Exports our breakpoints into a JSON in a certain css class so our JS can read from it.
$mediaBreakPoints: (
  screen-phone-min: $screen-phone-min,
  screen-phone-max: $screen-phone-max,
  screen-tablet-min: $screen-tablet-min,
  screen-tablet-max: $screen-tablet-max,
  screen-laptop-min: $screen-laptop-min,
  screen-laptop-max: $screen-laptop-max,
  screen-desktop-lg-min: $screen-desktop-lg-min,
  screen-desktop-lg-max: $screen-desktop-lg-max,
  screen-desktop-xl-min: $screen-desktop-xl-min,
);
