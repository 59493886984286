/* VARIABLES */

@import "../core/scss/cr-variables-colors";
@import "../core/scss/cr-variables";
@import "../core/scss/cr-base";




/* CONTENT */

@import "../core/scss/cr-base";
@import "../core/scss/cr-mixin";
@import "../core/scss/cr-validations";
@import "../core/scss/cr-animated";


/* EFFECTS */

@import "../core/scss/cr-resize-image-effect";

/* ELEMENTS */

@import "../core/scss/cr-buttons";
@import "../core/scss/cr-icheck";
@import "../core/scss/cr-modals";
@import "../core/scss/cr-forms";
@import "../core/scss/cr-graphic-circle";
@import "../core/scss/cr-header-menu";



/* COMPONENTS */

@import "../core/scss/cr-user-profile";
@import "../core/scss/cr-menu_mobile";
@import "../core/scss/cr-header";
@import "../core/scss/cr-loader";



/* LOAN APPLICATION */

@import "../core/scss/cr-application";
@import "../core/scss/cr-application-bloc";
@import "../core/scss/cr-application-menu";
