.glass {
  width: 275px;
  height: 275px;
  position: absolute;
  border-radius: 50%;
  cursor: crosshair;

  box-shadow:
          0 0 0 7px rgba(255, 255, 255, 0.85),
          0 0 7px 7px rgba(0, 0, 0, 0.25),
          inset 0 0 40px 2px rgba(0, 0, 0, 0.25);
  display: none;
}






