@mixin delete-margins{
  margin-top:       $remove-padding;
  margin-bottom:    $remove-padding;
  margin-left:      $remove-padding;
  margin-right:     $remove-padding;
}
@mixin delete-paddings {
  padding:          $remove-padding;
}
@mixin separator-line{
  height:           $separator-height;
  background-color: $gray;
  margin-top:       $margin-base;
  margin-bottom:    $margin-base;
  clear: both;
}
@mixin delpaddingmargin {
  margin: 0;
  padding: 0;
}
@mixin inputfullwidth{
  max-width: $full-width;
  width: auto;
}
@mixin marginrightimput{
  width: auto;
  margin-right: ($margin-bigger + 10);
}
@mixin photo-user-profile{
  background-color: $purple-light;
  background-size: auto $full-width;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(".../img/photo-user.jpg");
  border-radius: $border-circle;
  border: 5px solid $white;
  overflow: hidden;
  height: 130px;
  width: 130px;
  position: relative;
}
