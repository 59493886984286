.cr-icon-menu-mobile-container{
  display: none;
}

@media (max-width: $screen-tablet-max){
  .cr-icon-menu-mobile-container{
    display: block!important;
    padding-right: 10px;
    .cr-hamburger {
      display: block;
      position: relative;
      overflow: hidden;
      margin: 0;
      padding: 0;
      width: 50px;
      height: 50px;
      font-size: 0;
      text-indent: -9999px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      box-shadow: none;
      border-radius: none;
      border: none;
      cursor: pointer;
      -webkit-transition: background 0.3s;
      transition: background 0.3s;
    }

    .cr-hamburger:focus {
      outline: none;
    }

    button {
      &.cr-hamburger {
        background-color: transparent;
      }
    }


    .cr-hamburger {
      span {
        display: block;
        position: absolute;
        top: 25px;
        width: 100%;
        //left: 18px;
       // right: 18px;
        height: 3px;
        background: $gray;
        &::after, &::before {
          position: absolute;
          display: block;
          left: 0;
          width: 100%;
          height: 3px;
          background-color: $gray;
          content: "";
        }
        &::after{
          bottom: -15px;
        }
        &::before{
          top: -15px;
        }
      }
    }

    .cr-hamburger span {
      -webkit-transition: background 0s 0.3s;
      transition: background 0s 0.3s;
    }

    .cr-hamburger span::before,
    .cr-hamburger span::after {
      -webkit-transition-duration: 0.3s, 0.3s;
      transition-duration: 0.3s, 0.3s;
      -webkit-transition-delay: 0.3s, 0s;
      transition-delay: 0.3s, 0s;
    }

    .cr-hamburger span::before {
      -webkit-transition-property: top, -webkit-transform;
      transition-property: top, transform;
    }
    .cr-hamburger span::after {
      -webkit-transition-property: bottom, -webkit-transform;
      transition-property: bottom, transform;
    }
    .cr-hamburger.is-active {
      //background-color: #cb0032;
    }
    .cr-hamburger.is-active span {
      background: none;
    }
    .cr-hamburger.is-active span::before {
      top: 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    .cr-hamburger.is-active span::after {
      bottom: 0;
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
    .cr-hamburger.is-active span::before,
    .cr-hamburger.is-active span::after {
      -webkit-transition-delay: 0s, 0.3s;
      transition-delay: 0s, 0.3s;
    }
  }
}
