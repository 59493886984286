.cr-modal-menu-mobile{
  display: none;
  z-index: -1;
}

@media (max-width: $screen-tablet-max){
  .modal {
    &.cr-modal-menu-mobile{
      display: block!important;
      .modal-dialog{
        margin: 50px $remove-margin $remove-margin;
        overflow: hidden;
        .modal-content{
          border-radius: $remove-border;
          .modal-body{
            ul{
              &.cr-menu-mobile-content{
                display: block;
                padding-left: $remove-padding;
                li{
                  padding-top: $padding-small;
                  padding-bottom: $padding-small;
                  border-bottom: 1px solid rgba(0, 0, 0, .09);
                  &:last-child{
                    border-bottom: $remove-border;
                  }
                  i{
                    font-size: 3rem;
                    position: relative;
                    top: 5px;
                    color: #2e6089;
                    margin-right: $margin-small;
                  }
                  span{
                    font-size: $font-medium;
                    position: relative;
                    top: -5px;
                    a{
                      color: #2e6089 !important;
                    }
                  }
                  ul{
                    &.cr-submenu{
                      display: block;
                      margin-left: $margin-base * 2;
                      li{
                        border: $remove-border;
                        padding-bottom: $remove-padding;
                        padding-top: $remove-padding;
                        &:first-child{
                          padding-top: $remove-padding;
                        }
                        &:last-child {
                          padding-bottom: $remove-padding;
                        }
                        i{
                          font-size: 1.8rem;
                          margin-right: $margin-small;
                          color: #5e90b9!important;
                        }
                        a{
                          text-transform: uppercase;
                          font-size: 1rem;
                          position: relative;
                          top: -3px;
                          color: #5e90b9!important;
                        }

                      }
                    }
                  }
                }
              }
            }
          }
        }
        .cr-modal-background{
          background: url("../img/background-map-uber-home.png");
          -webkit-background-size: cover;
          -moz-background-size: cover;
          -o-background-size: cover;
          background-size: cover;
          height: 500px;
          top: 0;
          position: absolute;
          width: 100%;
          opacity:.6;
          z-index: 1070;
          margin-left: -15px;
        }
      }
      &.show{
        z-index:1050;
      }
    }
  }
}


