
form {
  &.cr-register-form, &.cr-basic-form {
    margin-top: 45%;
    width: 300px;
    .cr-cta-register-form {
      margin-right: 20px;
      text-align: right;
      margin-bottom: 20px;
      h2 {
        color: $orange;

      }
    }
    .abc-checkbox-primary {
      input {
        position: absolute;
      }
      label {
        font-family: $font-family-light;
        font-size: $font-small;
        margin-right: $margin-large;
        text-align: right;
      }

    }
    .cr-text-error-no-check {
      margin-right: $margin-large;
      margin-top: $margin-large;
      text-align: right;
      span {
        font-family: $font-family-light;
        font-size: $font-small;
      }

    }
    .cr-send-password-container {
      margin-bottom: $margin-medium;
      position: relative;
      top: 100px;
    }
    .cr-text-confirm-send-password-container {
      font-size: $font-small;
      text-align: center;
      margin-bottom: ($margin-xlarge + 5);
      h2 {
        margin-bottom: $margin-base;
        font-size: ($font-small + 2);
        color: $orange;
      }
    }
    .cr-text-error-no-check {
      margin-top: 10px;
      margin-bottom: 40px;
      text-align: center;
      i {
        color: $error-color;
        font-size: 22px;
        position: relative;
        top: 5px;
      }
      span {
        font-size: 14px;
      }
    }
    .cr-text-error-no-send-pass {
      margin-top: 10px;
      margin-bottom: 12px;
      text-align: center;
      i {
        color: $error-color;
        font-size: 22px;
        position: relative;
        top: 5px;
      }
      span {
        font-size: 14px;
      }
    }
    .cr-text-photos-description {
      ul {
        &.cr-list-group {
          margin-top: $margin-large;
          width: 96%;
          li {
            i {
              color: $valid-ok-color;
              left: $margin-base;
              position: relative;
              top: 2px;
            }
          }
        }
      }
      span {
        top: 3px;
        position: relative;
      }
    }
    .cr-text-start-container {
      padding: $padding-base $remove-padding;
      font-family: $font-family-light;
      h2 {
        margin: $remove-margin $remove-margin $margin-small $remove-margin;
        font-size: ($font-medium - 7);
        font-weight: $font-weight-bold;
      }
      span {
        font-size: $font-small;
      }
    }
    i {
      margin-right: 10px;
    }
    input, .floating-placeholder {
      //font-size: 20pt;
      line-height: 2.6em;
      min-height: 2.6em;
      height: inherit;
      margin: 0;
      padding: 0;
      width: 100%;
      margin-bottom: 15px;
      font-family: $font-family-light;
    }
    .floating-placeholder input:focus + label {
      color: $orange;
    }
    .floating-placeholder input[value] + label {
      color: red;
    }
    .floating-placeholder {
      position: relative;
    }
    .floating-placeholder input {
      //font-size: 20pt;
      border: none;
      outline: none;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      background: transparent;
      z-index: 2;
      border-bottom: 1px solid #ccc;
      text-indent: 20px;
    }
    .floating-placeholder:last-child input {
      //border-bottom: none;
    }
    .floating-placeholder label {
      display: block;
      position: absolute;
      top: 0;
      left: 20px;
      font-size: 16pt;
      z-index: 1;
      -moz-transform-origin: 0 0em;
      -ms-transform-origin: 0 0em;
      -webkit-transform-origin: 0 0em;
      transform-origin: 0 0em;
      -moz-transition: -moz-transform 160ms, color 200ms;
      -o-transition: -o-transform 160ms, color 200ms;
      -webkit-transition: -webkit-transform 160ms, color 200ms;
      transition: transform 160ms, color 200ms;
      -moz-transform: scale(1, 1) rotateY(0);
      -ms-transform: scale(1, 1) rotateY(0);
      -webkit-transform: scale(1, 1) rotateY(0);
      transform: scale(1, 1) rotateY(0);
      color: #fff;
    }
    .floating-placeholder-float label {
      -moz-transform: scale(0.55, 0.55) rotateY(0);
      -ms-transform: scale(0.55, 0.55) rotateY(0);
      -webkit-transform: scale(0.55, 0.55) rotateY(0);
      transform: scale(0.55, 0.55) rotateY(0);
      line-height: .6em;
      color: $orange;
      font-size: 18pt;
    }
    .floating-placeholder-float input {
      border-bottom: 1px solid $orange;
    }
    section {
      right: $margin-large;
      margin-top: $margin-large;
      position: absolute;
      button {
        font-family: $font-family-light;
      }
      i {
        font-size: $font-medium;
        position: relative;
        top: 5px;
      }
    }
  }
  &.cr-form-contact{
    padding: 9px;
    margin-left: 128px;
    margin-right: -302px;
  }
}


.cr-tooltip-template {
  display: none;
}

.cr-error {

  &:focus-within {
    .cr-tooltip-template {
      display: block;
      max-width: 310px;
      border: 1px solid black;
      text-align: center;
      line-height: 21px;
      border-radius: 5px;
      bottom: -48px;
      position: absolute;
      color: white;
      background-color: black;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      z-index: 9999;
      padding: 10px;
      font-size: .9rem;

      &:after {
        width: 0;
        position: absolute;
        height: 0;
        top: -16px;
        border-left: 10px solid black;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        content: "\00a0";

        -webkit-transform: rotate(270deg); // webkit - safari
        -moz-transform: rotate(-90deg); // mozilla
        -o-transform: rotate(-90deg); // opera
        -ms-transform: rotate(-90deg); // explorer
        rotation: 78deg;

        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
      }
    }
  }
}

@media (max-width: $screen-tablet-min) {

  .cr-error {
    &:focus-within {
      .cr-tooltip-template {

        max-width: 485px;
        border-radius: 0;
        top: 0;
        position: relative;
        line-height: 16px;
        display: flow-root;

        &:after {

          display: none;

        }
      }
    }
    &.cr-form-contact{
      padding: 9px;
      margin-left: -16px;
    }
  }

  .floating-placeholder {
    &.cr-error {
      .cr-tooltip-template {
        top: 3px;
        width: 98%;
        position: relative;
      }
    }
  }

  .cr-form-element {
    &.input-group {
      &.cr-error {
        .cr-tooltip-template {
          top: 60px;
          width: 90%;
          position: absolute;
        }
      }
    }
  }

  .cr-form-element {
    &.cr-input-form {
      &.cr-error {
        .cr-tooltip-template {
          top: 60px;
          width: 90%;
          position: absolute;
        }
      }
    }
  }

  form {
    width: 96% !important;
    &.cr-register-form, &.cr-basic-form {
      .abc-checkbox-primary {
        label {
          text-align: justify;
        }
      }
      .floating-placeholder input {
        border-radius: $remove-border;
        top: 2px;
      }
      .floating-placeholder input:focus + label {
        color: $white;
      }
      .floating-placeholder input[value] + label {
        color: red;
      }
      .floating-placeholder label {
        color: #c4c4c4;
      }
      .floating-placeholder-float label {
        color: $white;
        font-size: 30px;
        margin-top: 2px;
      }
      .floating-placeholder-float input {
        border-bottom: 1px solid $white;
      }
      section {
        margin: 0 auto;
        position: relative;
        right: 0;
      }
      .cr-privacy {
        position: relative;
        padding: $remove-padding;
        top: $margin-base;
        margin: $remove-margin;
      }
    }
      &.cr-form-contact{
        padding: 9px;
        margin-left: -16px;
      }
  }
}

@media (min-width: $screen-phone-max) and (max-width: $screen-laptop-min){

  .cr-form-element {
    &.cr-error {
      .cr-tooltip-template {
        top: 60px;
        width: 90%;
        position: absolute;
      }
    }
  }
  form {
      &.cr-form-contact{
        padding: 9px;
        margin-left: -16px;
      }
  }
}
