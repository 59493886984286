


//colors

$white:                     #fff !default;
$black:                     #000 !default;

$gray:                      #6d6d6d !default;
$gray-light:                #f2f2f2 !default;
$gray-desactive:            #c4c4c4 !default;

$gray-dark:                 #adadad !default;
$gray-text-form:            #a8a8a8 !default;
$gray-darkblack:            #464a4c !default;
$gray-text:                 #7f7f7f !default;

$blue:                      #729fd0 !default;
$blue-light:                #e3ecf6 !default;

$purple:                    #714985 !default;
$purple-light:              #9374a2 !default;

$orange:                    #de6300 !default;
$orange-light:              #f4cbaa !default;


$red:                       #c92b26 !default;
$red-light:                 #fd5f5a !default;

$green:                     #67c764 !default;
$green-light:               #bae9b9 !default;

$primary-brand:             $gray;
$secondary-brand:           $green !default;
$links-color:               $orange !default;
$links-color-light:         $orange-light !default;

$border-box-user-profile:   #d6dfe2 !default;


//validations

$error-color:                 $red-light !default;
$valid-ok-color:              $blue !default;
$icon-size-validation:        24px !default;


$validation-ok-color:          $green !default;
$active-color:                 $blue !default;
$disabled-color:               $gray-desactive !default;
$disabled-text-color:          $white !default;
$validation-text-ok-color:     $white !default;
$active-text-color:            $white !default;
$error-text-color:             $white !default;







//profile

$background-body-profile:   $blue-light !default;
$tabs-background:           $gray-light !default;
$tabs-color-active:         $orange !default;
$tabs-color-desactive:      $gray-desactive !default;

$border-circle:             50% !default;



