.cr-separator {
  height: 1px;
  background-color: #e3ecf6;
  margin-top: $margin-large;
  margin-bottom: $margin-large;
}
.cr-header-profile{
  background-color: $purple;
  height: 80px;
  position: relative;
  margin-top: 25px;
  span{
    color: $white;
    font-family: $font-family-light;
  }
  .cr-photo-container{
    position: absolute;
    margin-left: $margin-xlarge;
    margin-top:  -$margin-xlarge;
    z-index: 1;
    .cr-photo{
      @include photo-user-profile;
      i{
        bottom: -6px;
        color: $white;
        font-size: 129px;
        position: absolute;
      }
    }
    .cr-edit-photo{
      background-color: $orange;
      border-radius: $border-radius-base;
      bottom: $remove-margin;
      margin-right: $margin-medium;
      margin-top: $margin-base;
      padding: 2px $padding-base;
      text-align: center;
      span{
        font-size: $font-small;
      }
      i{
        position: relative;
        color: $white;
        font-size: $font-size-icons;
        top: 3px;
      }
    }
  }
  .cr-name-header-profile-container{
    position: relative;
    margin-left: 180px;
    top: $margin-large;
    .cr-user-name{
      font-size: $font-xlarge;
      font-weight: $font-weight-bold;
    }

  }
  .cr-mail-header-profile-container{
    position: relative;
    top: $margin-xlarge + 5;
    .cr-mail-profile{
      margin-right: $margin-large;
      .cr-mail-profile{
        margin-right: $margin-base;
      }
      .cr-password-profile{
        color: $orange-light;
      }
    }
  }
}
.cr-user-profile-container{
  .content-mobile{
    display: none;
  }
  //position: relative;
  //top: 5%;
  margin: 1% auto;
  width: 85%;
  ul{
    &.cr-menu-user-profile-container{
      display: block;
      padding-left: $remove-padding;
      width: 25%;
      a{
        &.collapsed{
          display: block;
          left: 10%;
          position: relative;
          width: 90%;

        }
        &.cr-active{
          margin-bottom: 3px;
          display: block;
          position: relative;
          left: 1px;
          z-index: 2;
          &.collapsed{
            width: 100%;
          }
          .card {
            &.cr-menu-card{
              background-color: #e3ecf6;
              border-right: 0;
              border-bottom: 1px solid $border-box-user-profile;
              padding-left: $padding-medium;
              span{
                color: $orange;
              }
              li{
                i{
                  color: $orange;
                }
              }
              &:hover{
                background-color: #e3ecf6;
                cursor: default;
              }
            }
          }
        }
        .card{
          border: 0;
          display: block;
          border-radius: $remove-border;
          padding: $padding-base;
          background-color: #f5f5f5;
          font-family: $font-family-light;
          &.cr-menu-card{
            border-bottom: 3px solid $white;
            border-right: 3px solid $white;
            i{
              color: $gray;
              font-size: 26px;
              position: relative;
              top:5px;
            }
            span{
              color: $gray;
            }
            li{
              padding-top: $padding-base;
              padding-bottom: $padding-base;
            }
            &:hover{
              background-color: #d6dfe2;
              cursor: pointer;
            }


            /*
            &.cr-active{
              background-color: #e3ecf6;
              border-right: 0;
              span{
                color: $orange;
              }
              li{
                i{
                  color: $orange;
                }
              }
              &:hover{
                background-color: #e3ecf6;
                cursor: default;
              }
            }
            */






          }
        }
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
  .cr-content-user-profile-container{
    width: 74%;
    font-family: $font-family-light;
    min-height: 390px;
    background-color: #e3ecf6;
    border-left: 1px solid $border-box-user-profile;
    border-right: 1px solid $border-box-user-profile;
    border-bottom: 1px solid $border-box-user-profile;

    .card{
      border: $remove-border;
      background-color: #e3ecf6;
    }
    .cr-info-user-profile-item{
      background-color: #e3ecf6;
      padding: 33px $padding-base $padding-base;

      .cr-header-content-tab-user-profile{
        margin-bottom: $margin-base+2;
        .cr-title-content-tab {
          margin-right: $margin-large;
            span{
              color: $gray-text;
            }
        }
        .cr-title-value-content-tab{
          position: relative;
          top:-8px;
            span{
              color: $orange;
              font-size: $font-medium;
            }
        }
      }
      .cr-body-content-tab-user-profile{
        background-color: $white;
        padding: $padding-base;
        &.cr-body-user-info{
          .card{
            background-color: $white;
            border-top: $remove-border;
            border-bottom: $border-base;
            border-radius: $remove-value;
            padding: $padding-base;
            &:nth-child(1){
              padding: $remove-padding $padding-base $padding-base;
            }
            .cr-title-item-user-profile{
              width: 30%;
              span{
                color: #465a5c;
                font-size: $font-small;
              }
            }
            .cr-header-info-user-profile-item{
              cursor: default;
            }
            .cr-content-title-item-user-profile{
              span{
                color: #829698;
                font-size: $font-small;
              }

            }
            .cr-content-info-user-profile-item{
              margin-top: $margin-base;
              font-size: $font-xsmall;
              background-color: $white;
              padding: 3*$padding-base $padding-base $padding-base $remove-padding;
              overflow: hidden;
              form{
                width: 90%;
                margin: $margin-auto;
                input[placeholder], [placeholder], *[placeholder]{
                  color: $orange !important;
                  font-family: $font-family-light;
                  border-radius: $remove-border;

                }
                label{
                  color: $gray;
                  font-family: $font-family-light;
                }
                .cr-button-container{
                  display: flex;
                  margin-left: 30%;
                  section{
                    &:nth-child(1){
                      margin-right: 30px;
                    }
                  }
                }
              }
            }
            .cr-content-info-user-profile-item-map{
              padding: $padding-base;
              background-color: $white;
              margin-top: $margin-base;
              form{
                input[placeholder], [placeholder], *[placeholder]{
                  color: $orange !important;
                  font-family: $font-family-light;
                  border-radius: $remove-border;

                }
              }
            }
          }
        }
        &.cr-body-my-loan{
          .cr-header-my-loan{
            background-color: #e3ecf6;
            .cr-status-loan{
              position: relative;
              top:-10px;
              i{
                color: $green;
                font-size: $font-medium;
                position: relative;
                top:3px;
              }
              span{
                position: relative;
                top:-3px;
                color: $gray-text;
              }
            }
          }
          .cr-detail-my-loan{
            position: relative;
            padding-bottom: 40px;
            margin-bottom: 10px;
            font-size: $font-small;
            .cr-numbers-my-loan{
              width: 80%;
              ul{
                display: block;
                width: 100%!important;
                padding-left: $padding-base;
                li{
                  margin-bottom: $margin-base;
                  span{
                    &:nth-child(1){
                      color: $gray-text;
                      margin-right: $margin-small;
                    }
                    &:nth-child(2){
                      color: $green;
                    }
                  }
                }
              }
            }
            .cr-graphic-my-loan{
              width: 20%;
              .cr-circle {
                cursor: $cursor-on;
              }
              .cr-results-graphic-my-loan{
                position: absolute;
                bottom: 0;
                .cr-square-pending-payment-container{
                  display: flex;
                  .cr-square-pending-payment{
                    width: 10px;
                    height: 10px;
                    background-color: $gray-desactive;
                    margin-bottom: $margin-small;
                    margin-right: 4px;
                  }
                  span{
                    position: relative;
                    top:-4px;
                    color: $gray-text;
                  }
                }
                .cr-square-amortized-payment-container{
                  display: flex;
                  .cr-square-amortized-payment{
                    width: 10px;
                    height: 10px;
                    background-color: $green;
                    margin-bottom: $margin-small;
                    margin-right: 4px;
                  }
                  span{
                    position: relative;
                    top:-4px;
                    color: $green;
                  }
                }
              }
            }

          }
          .cr-next-payment{
            width: 100%;
            ul{
              display: block;
              width: 100%!important;
              padding-left: $padding-base;
              li{
                margin-bottom: $margin-base;
                &:nth-child(1){
                  span{
                    display: block;
                    margin-top: $margin-small;
                  }

                }
                span{
                  &:nth-child(1){
                    color: $gray-text;
                    margin-right: $margin-small;
                  }
                  &:nth-child(2){
                    color: $green;
                  }
                }
              }
            }
          }
          .cr-next-payment{
            ul{
              display: block;
              li{
                margin-bottom: $margin-base;
                span{
                  &:nth-child(1){
                    color: $gray-text;
                    margin-right: $margin-large;
                  }
                  &:nth-child(2){
                    color: $green;
                  }
                }
              }
            }
          }
          .cr-download-documents-my-loan{;
            padding-bottom: 15px;
            margin: 0 auto;
            display: table;

          }
          .cr-general-info-my-loan{
            padding: $remove-padding;
            .cr-total-loan{
              width: 33%;
              text-align: center;
              display: grid;
              :nth-child(1){
                margin-bottom: $margin-base;
                color: $gray-text;
              }
              :nth-child(2){
                color: $green;
                font-weight: $font-weight-bold;
              }
            }
            .cr-date-start-loan{
              width: 33%;
              text-align: center;
              display: grid;
              :nth-child(1){
                margin-bottom: $margin-base;
                color: $gray-text;
              }
              :nth-child(2){
                color: $green;

              }
              border-left: 1px dotted $gray-desactive;
              border-right: 1px dotted $gray-desactive;
            }
            .cr-date-end-loan{
              width: 33%;
              text-align: center;
              display: grid;
              :nth-child(1){
                margin-bottom: $margin-base;
                color: $gray-text;
              }
              :nth-child(2){
                color: $green;
              }
            }
          }
        }
        &.cr-body-payment-planning{
          .cr-general-info-payment-planning{
            padding: $remove-padding;
            .cr-total-loan{
              width: 33%;
              text-align: center;
              display: grid;
              :nth-child(1){
                margin-bottom: $margin-base;
                color: $gray-text;
              }
              :nth-child(2){
                color: $green;
              }
            }
            .cr-date-start-loan{
              width: 33%;
              text-align: center;
              display: grid;
              :nth-child(1){
                margin-bottom: $margin-base;
                color: $gray-text;
              }
              :nth-child(2){
                color: $green;

              }
              border-left: 1px dotted $gray-desactive;
              border-right: 1px dotted $gray-desactive;
            }
            .cr-date-end-loan{
              width: 33%;
              text-align: center;
              display: grid;
              :nth-child(1){
                margin-bottom: $margin-base;
                color: $gray-text;
              }
              :nth-child(2){
                color: $gray-text;
              }
            }
          }
          .cr-share-payment-planing{
            ul{
              display: list-item;
              margin-bottom: 40px;
              li{
                text-align: center;
                width:90%;
                padding-top: 5px;
                padding-bottom: 5px;
                &:nth-child(1){
                  margin-bottom: $margin-base;
                }
                &:nth-child(even){
                  background-color: #ededed;
                  color: $gray-text;
                }
                :nth-child(1){
                  width: 25%;
                }
                :nth-child(2){
                  width: 50%;
                }
                :nth-child(3){
                  width: 25%;
                }
              }
            }

          }
          .cr-pagination{
            margin: $margin-auto;
            display: table;
            padding-bottom: 15px;
            a{
              color: $orange;
            }
          }
        }
        &.cr-body-contact-method{
          ul{
            padding-left: $remove-padding;
            li{
              &.cr-content-contact-metod{
                width: 25%;
                border: 1px solid $gray;
                margin-right: $margin-base;
                padding: $padding-base;
                overflow: hidden;
                background-color: #f8f8f8;
                text-align: center;
                .cr-sub-title{
                  margin-top: $margin-base;
                  margin-bottom: $margin-base;
                  display: block;
                }
                ul{
                  display: block;
                  li{
                    margin-bottom: $margin-base;
                    span{
                      font-size: 14px;
                    }
                  }
                }
                .cr-contact-phones-user-profile{
                  color: $gray-text;

                }
                .cr-social-network-user-profile{
                  color: $gray-text;
                }
                .cr-address-contact-user-profile{
                  color: $gray-text;
                  span{
                    display: block;
                    margin-bottom: 5px;
                  }
                }
                &:nth-child(1){
                  border-color: $purple ;
                  margin-left: 0;
                  width: 24%;
                  .cr-sub-title{
                    color: $purple;
                    font-size: $font-small-large;
                  }
                  .cr-icon-container-contact-profile{
                    border: 1px solid  $purple ;
                    margin-left: 6%;
                    i{
                      color: $purple;
                    }
                  }
                  i{
                    color: $purple;
                    font-size: $font-medium;
                    position: relative;
                    top: 4px;
                  }
                 }
                &:nth-child(2){
                  border-color: $blue ;
                  width: 22%;
                  .cr-sub-title{
                    color: $blue;
                    font-size: $font-small-large;
                  }
                  .cr-icon-container-contact-profile{
                    border: 1px solid  $blue ;
                    margin-left: 6%;
                    i{
                      color: $blue;
                    }
                  }
                  i{
                    color: $blue;
                    font-size: $font-medium;
                    position: relative;
                    top: 4px;
                  }
                }
                &:nth-child(3){
                  border-color: $green;
                  width: 60%;
                  .cr-sub-title{
                    color: $green;
                    font-size: $font-small-large;
                  }
                  .cr-icon-container-contact-profile{
                    border: 1px solid $green ;
                    margin-left: 6%;
                    i{
                      color: $green;
                    }
                  }
                  margin-right: 0;
                }
                .cr-icon-container-contact-profile{
                  border-radius: 50%;
                  width: 50px;
                  height: 50px;
                  text-align: center;
                  margin: 0 auto!important;
                  background-color: $white;
                  i{
                    position: relative;
                    top: 20%;
                    font-size: $font-large;
                  }

                }
              }
            }
          }
        }
        &.cr-resumen-info-loan-container{
        }
      }
    }
  }
}

@media (max-width: $screen-phone-max){
  body {
    background-color: #e3ecf6;
    font-size: $font-small;
  }
  .cr-header-profile {
    font-size: $font-xsmall!important;
    .cr-photo-container{
      margin-left: $margin-small;
      .cr-edit-photo{
        display: none;
      }
    }
    .cr-name-header-profile-container{
      margin-left: 150px;
      span {
        display: block;
      }
      .cr-user-name{
        font-size: $font-base;
      }
    }
    .cr-mail-header-profile-container{
      display: none;
    }
  }
  ul{
    &.cr-menu-user-profile-container{
      .collapse{
        left: 0!important;
      }
      .collapsed{
        width: 100%!important;
        left: 0!important;
        i{
          &.icon-cr-next{
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            rotation: 0deg;
            filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
            margin-right: 0;
          }
        }
      }
        i{
          &.icon-cr-next{
            color: $orange!important;
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            rotation: 90deg;
            filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
            margin-right: 10px;
          }
        }
    }
  }
  .cr-user-profile-container{
    overflow: hidden;
    width: 100%;
    ul{
      float: none !important;
      margin: 0 auto;
      width: 92% !important;
      &.cr-menu-user-profile-container{
        a{
          .card{
            &.cr-menu-card{
              border-bottom: 0 solid #e3ecf6;
              border-right: 0 solid #e3ecf6;
              span{
                font-size: $font-base;
              }
            }
          }
        }
        .cr-content-tab{
          margin-bottom: 10px;
        }
      }
      .card{
        background-color: #fff!important;
      }
    }
    .content-desktop{
      display: none;
    }
    .content-mobile{
      display: block;
      .cr-content-user-profile-container{
        width: 100%;
        min-height: 0;
        background-color: $white;
        border:0;
        .card{
          background-color: white;
          padding: 0;
        }
        .cr-title-item-user-profile{
          width: 100% !important;
        }
        .cr-info-user-profile-item{
          padding-top: $remove-padding;
          background-color: $white;
          .cr-graphic-my-loan{
            float: none!important;
            margin: $margin-auto;
            width: 45% !important;
          }
          .cr-header-my-loan{
            background-color: $white;
            padding: $padding-base;
            padding-bottom: $remove-padding;
            border-bottom: 1px solid  #e3ecf6;
            .cr-label-my-loan{
              margin-bottom: $margin-base;
            }
          }
          .cr-body-my-loan{
            .cr-numbers-my-loan{
              width: 100%!important;
              ul{
                padding-left: 0!important;
              }
            }
            .cr-general-info-my-loan{
              padding-top: $remove-padding;
              font-size: 14px;
              .cr-total-loan{
                display: block;
                width: $full-width;
                text-align: left;
                span{
                  margin: 0!important;
                  &:nth-child(1){
                    font-size: 12px;
                  }
                }
              }
              .cr-date-start-loan{
                display: block;
                width: $full-width;
                text-align: left;
                span{
                  margin: 0!important;
                  &:nth-child(1){
                    font-size: 12px;
                  }
                }
                border: 0;
                margin-top: $margin-base;
                margin-bottom: $margin-base;
              }
              .cr-date-end-loan{
                display: block;
                width: $full-width;
                text-align: left;
                span{
                  margin: 0!important;
                  &:nth-child(1){
                    font-size: 12px;
                  }
                }
              }
            }
          }
          .cr-header-payment-planning{
            background-color: $white;
            text-align: center;
            div {
              float: none!important;
              &.cr-label-payment-planning{
                margin-bottom: $margin-small;
              }
            }
          }
          .cr-body-payment-planning{
            padding-top: $remove-padding;
            .cr-total-loan{
              display: block!important;
              width: $full-width!important;
              text-align: left!important;
              span{
                margin: 0!important;
              }
            }
            .cr-date-start-loan{
              display: block!important;;
              width: $full-width!important;
              text-align: left!important;
              span{
                margin: 0!important;
              }
              border: 0!important;
              margin-top: $margin-base!important;
              margin-bottom: $margin-base!important;
            }
            .cr-date-end-loan{
              display: block!important;
              width: $full-width!important;
              text-align: left!important;
              span{
                margin: 0!important;
              }
            }
            .cr-share-payment-planing{
              ul{
                padding-left: $remove-padding;
                width: 100%;
                li{
                  width: 100%;
                  .cr-share-number{
                    font-size: $font-small;
                    width: 15%;
                  }
                  .cr-share-date-payment{
                    font-size: $font-small;
                    width: 65%;
                  }
                  .cr-share-amount{
                    font-size: $font-small;
                    width: 20%;
                  }
                }
              }
            }

          }
        }
        .cr-header-content-tab-user-profile{
          display: none;
        }
        .cr-body-content-tab-user-profile{
          &.cr-body-contact-method{
            ul{
              width: 100%;
              display: block;
              li{
                &.cr-content-contact-metod{
                  border-radius: 0;
                  &:nth-child(1){
                    background-color: #fff4ff;
                  }
                  &:nth-child(2){
                    background-color: #f4fdff;
                  }
                  &:nth-child(3){
                    background-color: #f2fff1;
                  }
                  width: 100%!important;
                  padding: $padding-base $remove-padding;
                  margin: $remove-margin $remove-margin $margin-base!important;
                  display: block;
                  position: relative;
                  text-align: left;
                  min-height: 80px;
                  ul{
                    margin-left: 30px;
                  }
                  .cr-address-contact-user-profile{
                    margin-left: $margin-medium;
                  }
                  .cr-icon-container-contact-profile{
                    width: 30px;
                    height: 30px;
                    left: -6px;
                    top: -1px;
                    border-radius: $remove-border ;
                    position: absolute;
                    margin-left: 5px!important;
                    i{
                      font-size: ($font-small+4);
                    }
                  }
                  .cr-header-content{
                    display: inline;
                    i{
                      top:6px;
                    }
                  }
                  .cr-sub-title{
                    margin-left: 50px;
                    margin-top: -3px;
                    font-size: 20px!important;
                  }
                  .cr-contact-phones-user-profile{
                     li{
                       margin-bottom: $remove-margin!important;
                       i{
                         font-size: $font-small+2;
                         top:2px;
                       }
                       span{
                         font-size: ($font-small);
                       }
                     }
                   }
                  .cr-social-network-user-profile{
                    li{
                      margin-bottom: $remove-margin!important;
                      i{
                        font-size: $font-small+2;
                        top:3px;
                      }
                      span{
                        font-size: ($font-small);
                      }
                    }
                  }
                  .cr-address-contact-user-profile{
                    span{
                      margin-bottom: $margin-small;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }


}

@media (max-width: $screen-tablet-max) {
  .cr-mail-header-profile-container{
    display: none;
  }
}

@media (max-width: $screen-laptop-max) {
  .cr-mail-header-profile-container{
    display: none;
  }
}





