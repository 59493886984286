.cr-header-menu-container{
  ul{
    position: relative;
    top: 15px;
    margin: $remove-margin;
    width: 100%;
    display: inline-flex;
    li{
      margin-right: $margin-xlarge;
      a{
        font-family: $font-family-light;
        color: $gray-dark!important;
        &:hover{
          color: #2e6089!important;
        }
      }
      &:hover{
        color: #2e6089;
        cursor: pointer;
      }
      &.cr-menu-active{
        border-bottom: 3px solid #2e6089;
        padding-bottom: 20px;
        cursor: default;
        a{
          cursor: pointer;
          color: #2e6089!important;
        }
      }
    }
  }
}
@media (max-width: $screen-laptop-min){
  .cr-header-menu-container{
    display: none!important;
  }
}
