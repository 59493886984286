.cr-customer-container {
  background-color: $gray-light;
  padding: $remove-padding;
  margin: 0 auto 0;
  width: $full-width;
  height: $full-height;
  .card {
    border: 0;
  }
  .cr-request-forms-container {
    padding: $padding-base;
    background-color: $gray-light;
    margin-right: $remove-margin;
    margin-left: $remove-margin;
    .cr-form-container {
      .cr-show-help-info {
        border: 1px dotted $gray-dark;
        border-radius: $border-radius-base;
        margin-bottom: $margin-large;
        padding: $padding-base;
        &.cr-buro-KO, &.cr-review-info, &.cr-view-text{
          background-color: $error-color;
          border-color: $error-color;
          margin: 0 auto ($margin-large + 2);
          width: ($half-width + 10);
          span{
            color:  $white !important;
            font-size: ($font-medium - 7);
          }
        }
        span {
          font-size: ($font-small - 2);
          text-align: justify;
          color: $gray-dark;
        }
      }
      .cr-separate-entrecalles {
        background-color: $blue-light;
        border: 2px dotted $gray-desactive;
        margin-bottom: $margin-base;
        padding-left: $padding-base;
        padding-top: $padding-small;
        border-radius: $border-radius-base;
        span {
          font-size: $font-small;
          color: $blue;
        }
      }
      .cr-id-aplicacion{
        margin: $margin-auto;
      }
    }
    .cr-row-form-container {
      margin-bottom: $margin-medium;
      &.cr-referencias-row {
        &:nth-child(2n+1) {
          margin-bottom: $margin-base;
        }
        &:nth-child(2n) {
          border-bottom: 1px dotted $gray-dark;
          border-top: 1px dotted $gray-dark;
          padding-bottom: $padding-base;
          padding-top: $padding-base;
        }
      }
      &.cr-container-msg-photos{
        .cr-text-photos-ok{
          background-color: $white;
          border: 1px solid $valid-ok-color;
          border-radius: 10px;
          margin: ($margin-xlarge + 5) auto 0;
          padding: $padding-large;
          text-align: center;
          width: 67%;
          label{
            cursor: $cursor-on;
          }
        }
        i{
          position: relative;
          top:2px;
        }
      }
      &.cr-privacy {
        text-align: center;
        top: $margin-base;
        .abc-checkbox {
          label {
            cursor: pointer;
            font-size: $font-small;
          }
        }
      }
      .cr-icon-add-refference {
        i {
          font-size: $font-small-large;
          &:hover {
            cursor: pointer;
            color: $green;
          }
        }
      }
      .cr-info-extra-form-container {
        font-size: $font-xsmall;
        padding-top: $padding-base;
        color: $gray;
        cursor: default;
        .cr-info-extra-link-form {
          //color: $blue;
          //text-decoration: underline;
          cursor: $cursor-on;
        }
      }
      .cr-form-element {
        padding: $remove-padding;
        border: $border-form-initial;
        border-radius: $border-radius-base;
        margin-right: $margin-medium;
        background-color: $gray-light;
        width: ($full-width - 1);
        &.cr-big-birthday-container {
          max-width: $full-width;
          width: auto;
        }
        &.cr-big-employment-status-options-container{
          @include inputfullwidth;
        }
        &.cr-phones-disclaimer {
          width: ($half-width - 2);
        }
        &.col-md-2{
          flex: 0 0 31%;
          max-width: 31%;
          &.cr-big-name-surname-container{
            max-width: 34%;
          }
          &.cr-big-cp-container{
            flex: 0 0 16%;
            max-width: 16%;
          }
          &.cr-big-state-container{
            max-width: $full-width;
            width: auto;
            flex: 0 0 20%;
          }
          &.cr-big-municipality-container{
            max-width: $full-width;
            width: auto;
            flex: 0 0 29%;
          }
          &.cr-big-N-container{
            flex: 0 0 15%;
            max-width: 15%;
          }
          &.cr-big-N-interior-container{
            flex: 0 0 15%;
            max-width: 15%;
          }
          &.cr-big-square-container{
            flex: 0 0 43%;
            max-width: 25%;
          }
        }
        &.col-md-3{
          flex: 0 0 25%;
          max-width: 25%;
          &.cr-ife-container, &.cr-ife-rfc{
            flex: 0 0 38%;
            max-width: 38%;
          }
        }
        &.col-md-5{
          flex: 0 0 38%;
          max-width: 38%;
          &.cr-big-parent-marital-container{
            @include inputfullwidth;
          }
          &.cr-big-colonia-name-container{
            @include inputfullwidth;
            flex: 0 0 28%;
          }
          &.cr-civil-big-container{
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
        &.col-md-6 {
          flex: 0 0 44%;
          max-width: 44%;
          &.cr-when-call-you{
            flex: 0 0 32%;
            @include inputfullwidth;
            overflow: visible!important;
            .cr-label-mobile{
              width: 95%;
            }
          }
          &.cr-name-bank-list-container{
            flex: 0 0 43%;
            max-width: 43%;
          }
          &.cr-name-bank-list1-container{
            flex: 0 0 50%;
            max-width: 50%;
          }
          &.cr-big-number-dependants-container{
            @include inputfullwidth;
            flex: 0 0 45%;
          }
          &.cr-big-reference-type-container{
            @include inputfullwidth;
          }
          &.cr-big-time-in-home-container{
            @include inputfullwidth;
          }
          &.cr-big-automotriz-container{
            @include inputfullwidth;
            flex: 0 0 50%;
          }

        }
        .cr-icon-input-icon-container {
          padding: $padding-icon-forms;
          background-color: $gray-dark;
          i {
            position: relative;
            top: 2px;
            color: $white;
            font-size: $font-medium;
          }
        }
        .cr-elements-form-container {
          color: $gray;
          font-size: $font-small;
          padding-top: $padding-base;
          padding-left: $padding-base;
          width: 82%;
          &.cr-small-parent-marital-container {
            @include marginrightimput;
          }
          &.cr-small-birthday-container {
            @include marginrightimput;
          }
          &.cr-small-colonia-name-container{
            @include marginrightimput;
          }
          &.cr-small-number-dependants-container {
            @include marginrightimput;
          }
          &.cr-small-reference-type-container{
            @include marginrightimput;
          }
          &.cr-small-time-in-home-container{
            @include marginrightimput;
          }
          &.cr-small-employment-status-options-container{
            @include marginrightimput;
          }
          &.cr-small-name-surname-container{
            @include marginrightimput;
          }
          &.cr-small-cp-container{
            margin-right: 50px;
            width: 40%;
          }
          &.cr-small-state-container{
            width: 55%;
          }
          &.cr-small-municipality-container{
            width: 70%;
          }
          &.cr-small-N-container{
            width: 30%;
          }
          &.cr-small-N-interior-container{
            width: 40%;
          }
          &.cr-small-square-container{
            width: 61%;
          }
          &.cr-job-description-container, &.cr-reason-description-container{
            width: 94%;
          }
          &.cr-when-call-container{
            @include marginrightimput;
            padding-top: ($padding-base - 1);
          }
          &.cr-choose-option-container{
            width: 88%;
          }
          &.cr-name-bank-container{
            width: 88%;
          }
          &.cr-import-credits-container{
            width: 90%;
          }
          &.cr-how-time-container{
            width: 75%;
          }
          .cr-span-text-request-forms {
            color: $blue;
            font-weight: $font-weight-base;
            font-size: $font-xsmall;
            position: relative;
            top:1px;
            cursor: default;
          }
          .cr-radio-check-container {
            .cr-radio-checks {
              position: relative;
              top: -1px;
              label {
                font-size: 12px;
                cursor: $cursor-on;
                &::after {
                  line-height: 1px;
                }
              }
            }
          }
          .cr-input-form-container {
            input {
              &.cr-input-form {
                background-color: transparent;
                border: $remove-border;
                border-radius: 0;
                color: $blue;
                font-weight: $font-weight-base;
                font-size: $font-xsmall;
                line-height: 20px;
                margin: 0 0 0 $margin-base;
                padding: 0;
                width: $full-width;
                height: 26px;
                position: relative;
                top: -4px;
                &.cr-special-form{
                  width: 72%!important;
                }
              }
            }
            textarea {
              background-color: transparent;
              border: 1px dotted $gray-dark;
              border-radius: $border-radius-base;
              color: $blue;
              font-size: ($font-xsmall + 2);
              font-weight: $font-weight-base;
              height: 64px;
              line-height: 1;
              margin: 0 $margin-small $margin-small;
              padding: $padding-base;
              position: relative;
              top: 0;
              width: $full-width;
            }
            input::-webkit-input-placeholder {
              color: $gray;
            }
            input:focus::-webkit-input-placeholder {
              color: $gray;
            }
            /* Firefox < 19 */
            input:-moz-placeholder {
              color: $gray;
            }
            input:focus:-moz-placeholder {
              color: $gray;
            }
            /* Firefox > 19 */
            input::-moz-placeholder {
              color: $gray;
            }
            input:focus::-moz-placeholder {
              color: $gray;
            }
            /* Internet Explorer 10 */
            input:-ms-input-placeholder {
              color: $gray;
            }
            input:focus:-ms-input-placeholder {
              color: $gray;
            }

            textarea::-webkit-input-placeholder {
              color: $gray;
            }
            textarea:focus::-webkit-input-placeholder {
              color: $gray;
            }
            /* Firefox < 19 */
            textarea:-moz-placeholder {
              color: $gray;
            }
            textarea:focus:-moz-placeholder {
              color: $gray;
            }
            /* Firefox > 19 */
            textarea::-moz-placeholder {
              color: $gray;
            }
            textarea:focus::-moz-placeholder {
              color: $gray;
            }
            /* Internet Explorer 10 */
            textarea:-ms-input-placeholder {
              color: $gray;
            }
            textarea:focus:-ms-input-placeholder {
              color: $gray;
            }

          }
          .cr-text-select-forms-container {
            padding: 7px 10px 0;
            .cr-text-select-forms {
              color: $gray-desactive;
            }
          }
          .cr-select-container {
            position: relative;
            margin-left: $margin-base;
            top: -2px;
            select {
              font-size: $font-small;
              background-color: $white;
              border: thin solid $gray-desactive;
              border-radius: $border-radius-base;
              display: inline-block;
              line-height: 1.5em;
              padding: 2px 3.5em 2px 1em;
              cursor: $cursor-on;
              font-weight: $font-weight-base;

              /* reset */

              margin: 0;
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
              -webkit-appearance: none;
              -moz-appearance: none;
            }
            select.cr-minimal {
              background-image:
                      linear-gradient(45deg, transparent 50%, gray 50%),
                      linear-gradient(135deg, gray 50%, transparent 50%),
                      linear-gradient(to right, #ccc, #ccc);
              background-position:
                      calc(100% - 20px) calc(10px + 0px),
                      calc(100% - 15px) calc(10px + 0px),
                      calc(100% - 2.5em) 1px;
              /*calc(100% - 20px) calc(1em + 2px),
              calc(100% - 15px) calc(1em + 2px),
              calc(100% - 2.5em) 0.5em;*/
              background-size:
                      5px 5px,
                      5px 5px,
                      1px 1.5em;
              background-repeat: no-repeat;
            }
            select.cr-minimal:focus {
              background-image: linear-gradient(45deg, green 50%, transparent 50%), linear-gradient(135deg, transparent 50%, green 50%), linear-gradient(to right, #ccc, #ccc);
              background-position: calc(100% - 15px) 10px, calc(100% - 20px) 10px, calc(100% - 2.5em) 1px;
              background-repeat: no-repeat;
              background-size: 5px 5px, 5px 5px, 1px 1.5em;
              outline: 0 none;
            }
            .cr-select-month {
              margin-left: $margin-small;
              margin-right: $margin-small;
            }
            .cr-select-colonia {
              margin-left: $margin-small;
              margin-right: $margin-small;
            }
            .cr-select-years-in-house {
              margin-left: $margin-small;
              margin-right: $margin-small;
            }
            .dropdown {
              position: relative;
              top: -2px;
              left: $margin-base;
              button {
                background-color: transparent;
                color: #C7C7C7;
                font-size: 12px;
                font-weight: 300;
                padding: 5px;
                box-shadow: none;
                cursor: $cursor-on;
                border: 1px solid #cecece;
              }
              .dropdown-menu {
                padding-left: $padding-base;
                padding-right: $padding-base;
                .cr-option-container {
                  .checkbox {
                    padding-left: $padding-base;
                    margin-bottom: $margin-small;

                    label {
                      font-size: $font-small;
                      cursor: $cursor-on;
                      color: $gray-dark;
                      &:hover{
                        font-weight: $font-weight-bold;
                        color: $blue;
                      }
                    }
                    input{
                      cursor: $cursor-on;
                    }
                  }
                }
              }
            }

          }
        }
        &.cr-photo-container {
          box-shadow: 3px 3px 3px $gray-dark;
          margin: $margin-auto;
          overflow: hidden;
          padding: $padding-large $padding-large ($padding-xlarge + 5);
          width: 19%;
          margin-right: $margin-base;
          .cr-container-title-photo{
            position: relative;
            top: -$margin-base;
          }
          .cr-photo-element {
            background-color: $gray-light;
            height: 200px;
            width: $full-width;
            overflow: hidden;
            text-align: center;
            img {
              width: auto;
              border: 1px solid $gray-desactive;
              height: $full-height;
            }
            .cr-loader-photo{
              left: $remove-value;
              margin: $margin-auto;
              top: 20%;
              img{
                height: auto !important;
                left: $remove-value !important;
                width: 70px !important;
                border: $remove-value;
              }
            }

          }
          .custom-file-control:lang(es)::after {
            content: "Seleccionar foto...";
          }
          .custom-file-control:lang(es)::before {
            content: "Examinar";
          }
          .custom-file-control:lang(en)::after {
            content: "Choose file...";
          }
          .custom-file-control:lang(en)::before {
            content: "Browse";
          }
          .custom-file-control::before {
            background-color: $blue;
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 0 0.25rem 0.25rem 0;
            bottom: -1px;
            color: $white;
            display: block;
            height: 1.5rem;
            line-height: 1.5;
            padding: 0.5rem 1rem;
            position: absolute;
            right: -1px;
            top: -1px;
            z-index: 6;
          }
          .custom-file-control {
            background-color: $white;
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 0.25rem;
            color: $gray-darkblack;
            height: 1.5rem;
            left: $remove-value;
            line-height: 1.5;
            padding: 0.5rem 1rem;
            pointer-events: none;
            position: absolute;
            right: $remove-value;
            top: $remove-value;
            z-index: 5;
          }
          .custom-file {
            cursor: pointer;
            display: inline-block;
            height: 2.5rem;
            margin-bottom: $remove-margin;
            margin-top: $margin-base;
            max-width: $full-width;
            position: relative;
          }
        }
      }
      .cr-send-password-container {
        margin-bottom: $margin-medium;
      }
      .cr-text-confirm-send-password-container {
        font-size: $font-small;
        text-align: center;
        margin-bottom: ($margin-xlarge + 5);
        h2 {
          margin-bottom: $margin-base;
          font-size: ($font-small + 2);
          color: $valid-ok-color;
        }
      }
      .cr-text-error-no-check {
        margin-top: 10px;
        margin-bottom: 40px;
        text-align: center;
        i {
          color: $error-color;
          font-size: 22px;
          position: relative;
          top: 5px;
        }
        span {
          font-size: 14px;
        }
      }
      .cr-text-error-no-send-pass {
        margin-top: 10px;
        margin-bottom: 12px;
        text-align: center;
        i {
          color: $error-color;
          font-size: 22px;
          position: relative;
          top: 5px;
        }
        span {
          font-size: 14px;
        }
      }
      .cr-text-photos-description {
        ul {
          &.cr-list-group {
            margin-top: $margin-large;
            width: 96%;
            li{
              i{
                color:  $valid-ok-color;
                left: $margin-base;
                position: relative;
                top: 2px;
              }
            }
          }
        }
        span {
          top: 3px;
          position: relative;
        }
      }
      .cr-text-start-container {
        padding: $padding-base $remove-padding;
        color: $gray-text-form;
        font-family: $font-family-light;
        h2 {
          margin: $remove-margin $remove-margin $margin-small $remove-margin;
          font-size: ($font-medium - 7);
          font-weight: $font-weight-bold;
        }
        span {
          font-size: $font-small;
        }
      }
    }
  }
  .th-footer-container{
    bottom: 0;
    position: relative;
  }
  .cr-discount-code {
    margin-top: 10px;
    margin-bottom: 10px;
    span{
      color: #1486ac;
      line-height: 25px;
      margin-right: 10px;
    }
    input{
      border-radius: 5px;
      padding: 5px;
      border: 1px solid $gray-light;
      margin-left: 10px;
      margin-right: 10px;
    }
    label{
      position: relative;
      top: 10px;
      float: right;
      right: 10px;
      margin-bottom: 10px;
      &.cr-valid-label-OK{
        color: $green;
      }
      &.cr-valid-label-KO{
        color: $red-light;
      }
    }
    .cr-input-form-container{
      display: flex;
      button{
        padding: 0.30rem 1.5rem !important;
        font-size: 1rem !important;
        text-transform: capitalize;
        width: 157px;
        margin-right: 10px !important;
      }
    }
  }
}


@media (max-width: $screen-phone-max) {
  &.cr-customer-container {
    padding: 0;
    .cr-progress-bar {
      left: 35px;
      margin-top: 10px;
      position: absolute;
      top: 15px;
      width: 80%;
      z-index: 1;
    }
    .cr-steps-containers {
      padding-top: 10px;
      width: 100%;
      //  background-color: red;
      .card {
        ul {
          &.nav-tabs {
            z-index: 10;
            padding-bottom: 10px;
            //margin: 0 auto;
            width: 100%;

            li {
              &.nav-item {
                width: 20%;
                padding-bottom: 0px;
                padding-top: 0px;

                a {
                  &.nav-link {
                    background-color: transparent;
                    border: 0;
                  }
                  &:hover{
                    border: 0;
                  }
                }
              }
              .cr-icon-container {
                // margin-right: $remove-margin !important;
                text-align: center;
              }
              .cr-step-description {
                text-align: center;
              }
              .cr-text-menu-mobile-steps{
                text-align: center !important;
              }
            }
          }
        }
      }
    }
    .cr-request-forms-container {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0!important;
      width: 100%;
      .cr-form-container {
        margin-right: 0;
        margin-left: 0;
        padding-left: 0;
        padding-right: 0;
        .cr-content-step1,
        .cr-content-step2,
        .cr-content-step3,
        .cr-content-step4,
        .cr-content-step5 {
          width: 100%;
          margin: 0 auto;
        }
        .cr-show-help-info {
          background-color: #f7f7f7;
          border: 1px dotted #adadad;
          border-radius: 5px;
          margin-bottom: 20px;
          padding: 10px;

          span {
            font-size: 12px;
            text-align: justify;
            color: #adadad;
          }
        }
        .cr-separate-entrecalles {
          padding: 10px;
          span{
            margin-bottom: 5px;
            font-size: 16px;
          }
        }
        .cr-container-msg-photos{
          margin-bottom: 15px!important;
        }
      }
      .cr-form-steps-container {
        padding-left: 10px;
        .cr-row-form-container {
          margin-bottom: 0;
          width: 97%;
          .cr-form-element {
            padding: 0;
            margin-bottom: 3px;
            overflow: hidden;
            &.cr-big-name-surname-container{
              width: 99.333% !important;
            }
            &.col-md-10, &.col-md-9, &.col-md-8, &.col-md-7, &.col-md-6, &.col-md-5, &.col-md-4, &.col-md-3, &.col-md-2, &.col-md-1 {
              flex: 0 0 99.3333%;
              max-width: 99.333%;
              width: 99.333%;
              &.cr-when-call-you{
                flex: 0 0 99.333%;
                max-width: 99.333%;
                width: 99.333%;
              }
            }
            &.cr-photo-container {
              width: 90%;
              margin-left: 10px;
              margin-bottom: 10px;
              border: 1px solid #adadad;
              border-radius: 0;
              font-size: 14px;
              box-shadow: 0 0 0 #b7b7b7!important;
              .cr-container-title-photo{
                top:0;
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 10px;
                font-weight: 400;
                color: #43a0cf;
                span{
                  font-size: 15px;
                }
              }
              .custom-file{
                ::before{
                  border-radius: 0!important;
                }
                margin-top: 0!important;
              }
              .custom-file-control{
                border-radius: 0!important;
              }
            }
            .cr-label-mobile {
              background-color: $gray;
              padding: 4px 10px;
              width: 100%;
              span {
                font-size: 14px;
                font-weight: 400;
                color: #999;
              }
            }
            .cr-elements-form-container {
              width: 97%;
              padding-top: 5px;
              &.col-md-9, &.col-md-7, &.col-md-10, &.col-md-11, &.col-md-8 {
                padding-right: 0;
              }
              &.cr-small-inputs, &.cr-medium-inputs {
                width: 60% !important;
              }
              .cr-span-text-request-forms {
                padding-top: 2px;
                top: 0;
                margin-bottom: 10px;
              }
              .cr-select-container {
                padding-top: 2px;
                margin-left: 0;
                padding-bottom: 10px;
                .dropdown{
                  position: relative;
                  top:5px !important;
                }
                .cr-select-month {
                  margin-left: 2px;
                  margin-right: 2px;
                }
              }
              .cr-input-form-container {
                padding-top: 0;
                padding-left: 0;
                input {
                  width: 100%;
                  font-size: 14px;
                }
              }
              .cr-select-container {
                top: 0;
                .dropdown {
                  top: -4px;
                  left: -1px;
                  .dropdown-menu {
                    &.dropdown-days {
                      width: 240px;
                      left: -80px;
                      .dropdown-menu-row {
                        padding-left: 18px;
                        padding-right: 0;
                        .dropdown-menu-element {
                          width: 8%;
                          line-height: .81;
                          border: 1px solid #dcdcdc;
                          padding-left: 24px;
                          padding-bottom: 10px;
                          text-align: center;
                          margin-left: 1px;
                          border-radius: 5px;
                          margin-bottom: 10px;
                          margin-right: 8px;
                          padding-right: 5px;
                        }
                      }
                    }
                    &.dropdown-month {
                      left: -80px;
                      .dropdown-menu-element {
                        width: 90%;
                      }
                    }
                    &.dropdown-year {
                      left: -210px;
                      .dropdown-menu-row {
                        padding-left: 18px;
                        padding-right: 0;
                        .dropdown-menu-element {
                          width: 16%;
                          line-height: .81;
                          border: 1px solid #dcdcdc;
                          padding-left: 24px;
                          padding-bottom: 10px;
                          text-align: center;
                          margin-left: 1px;
                          border-radius: 5px;
                          margin-bottom: 10px;
                          margin-right: 8px;
                          padding-right: 5px;
                        }
                      }
                    }
                  }
                  button {
                    color: #888;
                  }

                }

              }
              .cr-normacr-selects-container {
                margin-top: 0;
              }
              &.cr-small-inputs, &.cr-medium-inputs, &.cr-large-inputs {
                width: 100%;
              }
              .cr-radio-check-container {
                padding-bottom: 10px;
                span {
                  width: 100%;
                }
                .cr-radio-checks {
                  .radio {
                    &.abc-radio {
                      &.abc-radio-primary {
                        // float: none !important;
                      }
                    }
                  }
                  &.cr-special-radio-checks {
                    .radio {
                      &.abc-radio {
                        &.abc-radio-primary {
                          float: left !important;
                        }
                      }
                    }
                  }
                  &.cr-radio-checks-no-float {
                    .radio {
                      &.abc-radio {
                        &.abc-radio-primary {
                          float: none !important;
                        }
                      }
                    }
                  }
                }
              }
              .cr-input-form-container {
                .cr-input-form{
                  height: 30px;
                  margin-left: 0 !important;
                }
                textarea {
                  width: 89%;
                  &.cr-steps-textarea {
                    margin-left: 0;
                    position: relative;
                    left: 0;
                  }
                }
              }
            }
          }
          .cr-text-photos-description {
            font-size: 14px;
            margin: 0 auto 25px;
            width: 95%;
            ul {
              margin-left: 0;
              .list-group-item{
                border: none!important;
              }
            }
          }
          .cr-container-icon-add {
            float: left;
            width: 20%;
          }
          .cr-info-extra-form-container {
            float: left;
          }
          #show-me-text-debito {
            margin-left: 15px !important;
          }
          &.cr-container-msg-photos {
            .cr-text-photos-ok {
              border-radius: 5px;
              font-size: 14px;
              margin: 10px auto 0;
              padding: 5px;
              text-align: center;
              width: 85%;
              .styled {
                position: absolute;
              }
            }
          }
        }
      }
      .btn {
        padding-top: 0;
        .icon-cr-angle-double-right {
          font-size: 25px;
          position: relative;
          top: 6px;
        }
      }
    }
    .cr-bloque-container{
      width:100%;
      padding: 10px 10px 7px;
      margin-top: 20px;
      margin-left: 0!important;
      border-radius: 0!important;
      &.cr-small-size{
        width: 100%;
        border-radius: 0;
        .cr-row-form-container {
          &.cr-privacy {
            text-align: center;
            margin-bottom: 20px;
            margin-top: 5px;
            .abc-checkbox {
              label {
                cursor: pointer;
                font-size: 15px;
              }
            }
          }
          .cr-form-element{
            &.cr-phones-1a{
              width: 99%!important;
            }
            &:nth-child(2){
              margin-right: 0!important;
            }
          }
        }
      }

      section {
        &.clearfix {
          margin-bottom: 4px;
        }
      }
    }
    .cr-show-help-info{
      width: 85%;
    }

    /*temporal calculadora*/
    .cr-id-aplicacion {
      width: 100%;
    }
    .field.calculadora {
      float: none !important;
      margin: 0 !important;
    }
    .item.pago-semanal {
      width: 100% !important;
    }
    .selectormonto.block .calculadora {
      width: 386px !important;
    }
  }
  .cr-discount-code {
    margin: 0 auto;
    padding-top: 10px;
    padding-bottom: 10px;
    span{

    }
    input{
      width: 90px;
    }
  }
}
@media (min-width: $screen-tablet-min) and (max-width: $screen-laptop-max) {
  &.cr-customer-container {
    padding: 0;
    .cr-progress-bar {
      left: 10%;
      margin-top: 10px;
      position: absolute;
      top: 15px;
      width: 76%;
      z-index: 1;
    }
    .cr-steps-containers {
      //padding-top: 10px;
      //  background-color: #e80174;
      .card {
        ul {
          &.nav-tabs {
            z-index: 10;
            padding-bottom: 10px;
            //margin: 0 auto;
            width: 100%;

            li {
              &.nav-item {
                width: 20%;
                padding-bottom: 0px;
                padding-top: 0px;

                a {
                  &.nav-link {
                    background-color: transparent;
                    border: 0;
                  }
                  &:hover{
                    border: 0;
                  }
                }
              }
              .cr-icon-container {
                // margin-right: $remove-margin !important;
                text-align: center;
              }
              .cr-step-description {
                text-align: center;
              }
              .cr-text-menu-mobile-steps{
                text-align: center !important;
              }
            }
          }
        }
      }
    }
    .cr-request-forms-container {
      padding-left: 0;
      padding-right: 0;
      .cr-form-container {
        margin-right: 0;
        margin-left: 0;
        padding-left: 0;
        padding-right: 0;
        .cr-content-step1,
        .cr-content-step2,
        .cr-content-step3,
        .cr-content-step4,
        .cr-content-step5 {
          width: 92%;
          margin: 0 auto;
        }
        .cr-show-help-info {
          background-color: #f7f7f7;
          border: 1px dotted #adadad;
          border-radius: 5px;
          margin-bottom: 20px;
          padding: 10px;

          span {
            font-size: 12px;
            text-align: justify;
            color: #adadad;
          }
        }
        .cr-separate-entrecalles {
          padding-left: 10px;
          padding-right: 10px;
          padding-top: 10px;
        }
        .cr-bloque-container{
          width:98%;
          margin-top:15px!important;
        }
        .cr-id-aplicacion{
          text-align: center;
        }
      }
      .cr-drop-container {
        .cr-radio-checks{
          top: 5px!important;
        }
      }
      .cr-form-steps-container {
        padding-left: 10px;
        .cr-row-form-container {
          margin-bottom: 0;
          .cr-form-element {
            padding: 0;
            margin-right: 0;
            margin-bottom: 5px;
            &.col-md-10, &.col-md-9, &.col-md-8, &.col-md-7, &.col-md-6, &.col-md-5, &.col-md-4, &.col-md-3, &.col-md-2, &.col-md-1, {
              flex: 0 0 99.3333%;
              max-width: 99.333%;
            }
            &.col-md-2{
              &.cr-big-name-surname-container{
                width: 99.333%;
              }
              &.cr-big-state-container{
                width: 30%!important;
              }
            }
            &.col-md-5{
              &.cr-big-colonia-name-container{
                width: 60%!important;
              }
            }
            &.col-md-6 {
              &.cr-when-call-you {
                width: 99.333%;
              }
              &.cr-big-number-dependants-container {
                width: 99.333%;
              }
              &.cr-big-reference-type-container{
                width: 99.333%;
              }
              &.cr-big-time-in-home-container{
                width: 199.333%;
              }
            }
            &.cr-big-birthday-container{
              width: 100%;
            }
            &.cr-photo-container {
              width: 100%;
              margin-bottom: 10px;
              border: 1px solid #adadad;
              border-radius: 5px;
              font-size: 14px;
            }
            .cr-label-mobile {
              background-color: $gray;
              border-radius: 5px 0 0 5px;
              padding: 8px 10px;
              width: 20%!important;
              &.cr-special-height{
                height: 97px;
              }
              span {
                font-size: 12px;
                font-weight: 400;
                color: #999;
              }
            }
            .cr-elements-form-container {
              padding-top: 5px;
              width: 60%;
              &.col-md-9, &.col-md-7, &.col-md-10, &.col-md-11, &.col-md-8 {
                padding-right: 0;
              }
              &.cr-small-inputs, &.cr-medium-inputs {
                width: 60% !important;
              }
              .cr-span-text-request-forms {
                padding-top: 2px;
                top: 0;
                margin-bottom: 10px;
                &.cr-credit-label-text{
                  position: relative;
                  top: 5px;
                  width: 44%;
                }
                &.cr-automotriz-label-text{
                  position: relative;
                  top: 5px;
                  width: 60%;
                }
                &.cr-titular-label-text{
                  position: relative;
                  top: 5px;
                  width: 41%;
                }
              }
              .cr-input-form-container {
                padding-top: 0;
                padding-left: 0;
                input {
                  width: 100%;
                  margin-top: 5px;
                }
              }
              .cr-select-container {
                padding-top: 2px;
                margin-left: 10px;
                top: -3px;
                select {
                  font-size: 13px!important;
                  margin-top: 2px;
                }
                .dropdown {
                  top: 0;
                  left: -1px;
                  .dropdown-menu {
                    &.dropdown-days {
                      width: 240px;
                      left: -80px;
                      .dropdown-menu-row {
                        padding-left: 18px;
                        padding-right: 0;
                        .dropdown-menu-element {
                          width: 8%;
                          line-height: .81;
                          border: 1px solid #dcdcdc;
                          padding-left: 24px;
                          padding-bottom: 10px;
                          text-align: center;
                          margin-left: 1px;
                          border-radius: 5px;
                          margin-bottom: 10px;
                          margin-right: 8px;
                          padding-right: 5px;
                        }
                      }
                    }
                    &.dropdown-month {
                      left: -80px;
                      .dropdown-menu-element {
                        width: 90%;
                      }
                    }
                    &.dropdown-year {
                      left: -210px;
                      .dropdown-menu-row {
                        padding-left: 18px;
                        padding-right: 0;
                        .dropdown-menu-element {
                          width: 16%;
                          line-height: .81;
                          border: 1px solid #dcdcdc;
                          padding-left: 24px;
                          padding-bottom: 10px;
                          text-align: center;
                          margin-left: 1px;
                          border-radius: 5px;
                          margin-bottom: 10px;
                          margin-right: 8px;
                          padding-right: 5px;
                        }
                      }
                    }
                  }
                  button {
                    color: #888;
                    border-radius: 5px!important;
                  }

                }

              }
              .cr-normacr-selects-container {
                margin-top: 0;
              }
              &.cr-small-inputs, &.cr-medium-inputs, &.cr-large-inputs {
                width: 100%;
              }
              .cr-radio-check-container {
                padding-bottom: 10px;
                span {
                  width: 100%;
                }
                .cr-radio-checks {
                  top: 4px!important;
                  .radio {
                    &.abc-radio {
                      &.abc-radio-primary {
                        // float: none !important;
                      }
                    }
                  }
                  &.cr-special-radio-checks {
                    .radio {
                      &.abc-radio {
                        &.abc-radio-primary {
                          float: left !important;
                        }
                      }
                    }
                  }
                  &.cr-radio-checks-no-float {
                    .radio {
                      &.abc-radio {
                        &.abc-radio-primary {
                          float: none !important;
                        }
                      }
                    }
                  }
                }
              }
              .cr-input-form-container {
                textarea {
                  width: 97.33%;
                }
              }
              &.cr-small-colonia-name-container{
                width: 74%;
              }
            }
            &.col-md-special-med {
              width: 99.33%;
            }


          }
          #show-me-text-debito {
            margin-left: 15px !important;
          }
          .cr-text-photos-description {
            font-size: 14px;
            ul {
              margin-left: 0;
            }
          }
          .cr-container-icon-add {
            float: left;
            width: 20%;
          }
          .cr-info-extra-form-container {
            float: left;
          }
        }
      }
      .btn {
        padding-top: 0;
        .icon-cr-angle-double-right {
          font-size: 25px;
          position: relative;
          top: 6px;
        }
      }
    }
    .cr-bloque-container{
      width:100%;
      padding: 10px 10px 7px;
      margin-top: 20px;
      margin-left: 0!important;
      border-radius: 0!important;
      &.cr-small-size{
        width: 100%;
        border-radius: 0;
        .cr-row-form-container {
          &.cr-privacy {
            text-align: center;
            margin-bottom: 20px;
            margin-top: 5px;
            .abc-checkbox {
              label {
                cursor: pointer;
                font-size: 15px;
              }
            }
          }
          .cr-form-element{
            &.cr-phones-1a{
              width: 99%!important;
            }
            &:nth-child(2){
              margin-right: 0!important;
            }
          }
        }
      }
    }
  }
}
@media (min-width: $screen-desktop-lg-min) and (max-width: $screen-desktop-lg-max) {
  &.cr-customer-container {
    .cr-steps-containers {
      //   background-color: green;
    }
    .cr-request-forms-container {
      .cr-row-form-container {
        .cr-form-element{
          margin-bottom: 10px;


          &.col-md-2{
            flex: 0 0 24%;
            max-width: 24%;
            &.cr-big-name-surname-container{
              max-width: 31% !important;
            }
            &.cr-big-cp-container{
              flex: 0 0 17%;
              max-width: 17%;
            }
          }
          &.col-md-3 {
            flex: 0 0 35%;
            max-width: 35%;
            &.cr-ife-rfc, &.cr-ife-container {
              flex: 0 0 30%;
              max-width: 30%;
            }


          }
          &.col-md-4 {
            flex: 0 0 33.3333%;
            max-width: 43.333%;
          }
          &.col-md-5 {
            flex: 0 0 52%;
            max-width: 52%;
            &.cr-civil-big-container{
              flex: 0 0 52%;
              max-width: 52%;
            }
            &.cr-big-colonia-name-container{
              flex: 0 0 66%;
              max-width: 66%;
              width: 100%;
            }
            &.cr-big-entrecalles-container{
              flex: 0 0 49%;
              max-width: 49%;
            }
            &.cr-big-credit-container{
              flex: 0 0 53%;
              max-width: 53%;
            }
            &.cr-big-credit-card-container{
              flex: 0 0 55%;
              max-width: 55%;
            }
          }
          &.col-md-6 {
            flex: 0 0 75%;
            max-width: 75%;
            &.cr-name-bank-list1-container,&.cr-name-bank-list-container {
              flex: 0 0 55%;
              max-width: 100%;
            }
            &.cr-big-automotriz-container{
              flex: 0 0 70%;
              max-width: 70%;
            }
          }
          &.col-md-8 {
            flex: 0 0 96.6667%;
            max-width: 96.667%;
          }
          &.col-md-9 {
            flex: 0 0 100%;
            max-width: 100%;
          }
          .cr-elements-form-container {
            .cr-select-container {
              top: -4px;
              .dropdown {
                top: 0;
              }
            }
            &.cr-small-name-surname-container{
              width: 74%;
            }
            &.cr-small-cp-container{
              width: 64%;
            }
            &.cr-small-colonia-name-container{
              width: 77%;
            }
            .cr-span-text-request-forms {
              padding-top: 2px;
              top: 0;
              margin-bottom: 10px;
            }
          }
        }
      }

    }
    #stepFive{
      .cr-request-forms-container{
        .cr-form-steps-container{
          .cr-form-container{
            .cr-id-aplicacion{
              .stepsgroup{
                .field{
                  &.calculadora{
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: $screen-desktop-xl-min) {
  &.cr-container {
    .cr-steps-containers {
      //       background-color: purple;
    }
  }
}