html{
  margin: 0;
  font-family: $font-family-light;
  font-weight: $font-weight-base;
  pre,button,input,optgroup,select,textarea,h1, h2, h3, h4, h5, h6,.h1, .h2, .h3, .h4, .h5, .h6,.tooltip,.popover {
      font-family: $font-family-light;
      font-weight: $font-weight-base; 
    }
  ul {
    list-style: none;
  }
  b,strong {
    font-weight: bold;
  }
  a{
      color: $links-color;
      text-decoration: none;
      &.th-special-link{
        color: $links-color-light;
      }
      &:hover{
        color: $blue;
        text-decoration: underline;
      }
    }
  body {
    &.cr-customer-body-container{
      height: 93%;
    }
  }
}