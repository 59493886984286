.cr-bloque-container {
  width: 95%;
  background: $white;
  margin: $margin-bigger auto;
  position: relative;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 $margin-large*2 rgba(0, 0, 0, 0.1) inset;
  border-radius: $border-radius-base;
  padding: $padding-large $padding-large $padding-small;
  &.cr-small-size {
    width: $half-width;
    padding-bottom: $padding-medium;

  }
  &:before, &:after {
    content: "";
    position: absolute;
    z-index: -1;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
    top: $remove-value;
    bottom: $remove-value;
    left: $margin-base;
    right: $margin-base;
    border-radius: ($border-radius-small * 10) / $border-radius-small;
  }
  &:after {
    right: $margin-base;
    left: auto;
    transform: skew(8deg) rotate(3deg);
  }
}