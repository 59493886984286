.cr-header-container{
  background-color: $white;
  color: $gray;
  position: fixed;
  top: 0;
  padding: 5px 0;
  z-index: 90;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid $gray-light;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(143,143,143,0.52);
  -moz-box-shadow: 0px 2px 5px 0px rgba(143,143,143,0.52);
  box-shadow: 0px 2px 5px 0px rgba(143,143,143,0.52);
  .cr-header-logo-container, .cr-header-logo-mobile-container{
    margin-left: $margin-xlarge;
    padding-top: 2px;
    img{
      height: 50px;
      width: auto;
    }
  }
  .cr-header-claim-container {
    font-size: 1.2rem;
    margin-left: 13px;
    position: relative;
    top: 26px;
  }
}

.cr-header-margin-fixer {
  height: 60px;
}

@media (max-width: $screen-phone-max){
  .cr-header-container{
    z-index: 1090;

    .cr-header-claim-container {
        display: none;
    }
  }
}
@media (min-width: $screen-tablet-min) and (max-width: $screen-tablet-max) {
  .cr-header-container {
    z-index: 1090;

    .cr-header-claim-container {
        display: none;
    }
  }
}
@media (min-width: $screen-laptop-min) and (max-width: $screen-desktop-lg-max){
  .cr-header-container {
    z-index: 1090;
  }
}

